import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";

import clouds26 from "../../../Img/clouds26.png";
import AOS from "aos";

import img1 from "../../../Img/service-icons/domains-hosting/wide-range.svg";
import img2 from "../../../Img/service-icons/domains-hosting/reliable-solutions.svg";
import img3 from "../../../Img/service-icons/domains-hosting/scalability.svg";
import img4 from "../../../Img/service-icons/domains-hosting/enhanced-security.svg";
import img5 from "../../../Img/service-icons/domains-hosting/expert-support.svg";

export default function WhyChooseDomainsHosting() {
  useEffect(() => {
    AOS.init({
      once: true,
      offset: 120,
    });
  }, []);
  const whychoosedatalist = [
    {
      id: 1,
      Img: img1,
      Title: "Wide range of domain options",
      Desc: "From .com to .tech, we provide a diverse selection of domain extensions to suit your brand identity. Our easy-to-use domain search tool helps you find the perfect name that reflects your business.",
    },
    {
      id: 2,
      Img: img2,
      Title: "Reliable hosting solutions",
      Desc: "Our hosting plans are designed for performance and reliability. With 99.9% uptime guarantees, your website will be accessible to your audience whenever they need it.",
    },
    {
      id: 3,
      Img: img3,
      Title: "Scalability",
      Desc: "Whether you’re starting with a simple blog or managing a large e-commerce site, our hosting solutions can grow with you. Upgrade easily as your needs change without any hassle.",
    },
    {
      id: 4,
      Img: img4,
      Title: "Enhanced security",
      Desc: "Protecting your website and data is our priority. We offer SSL certificates, regular backups, and advanced security features to keep your online assets safe from threats.",
    },
    {
      id: 5,
      Img: img5,
      Title: "Expert support",
      Desc: "Our dedicated support team is available to assist you with any questions or issues. We’re here to ensure your website runs smoothly at all times.",
    },
  ];
  return (
    <>
      <section className="why-graphic-design section-padding-skew section-bg-skew bg-blue-lighteen service-bg-1">
        <Container>
          <Row className="mb-5">
            <div className="col-lg-12">
              <div
                className="sec-title"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                <img className="cloud" src={clouds26} alt="cloud" />
                <h2>
                  <span>Why choose us for domains and hosting?</span>
                </h2>
              </div>
            </div>
          </Row>
          <Row className="g-3 teammembers-wrap">
            {whychoosedatalist.map((whychoosedata) => (
              <div
                className="col-lg-6 col-md-6 col-sm-12 col-xs-12"
                key={whychoosedata.id}
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                <div className="crumina-module crumina-teammembers-item icon_box_left">
                  <div className="teammembers-thumb">
                    <img src={whychoosedata.Img} alt={whychoosedata.Title} />
                  </div>

                  <div className="teammember-content">
                    <div className="teammembers-item-prof">
                      {whychoosedata.Title}
                    </div>

                    <p>{whychoosedata.Desc}</p>
                  </div>
                </div>
              </div>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
}
