import React from "react";
import { Container, Row } from "react-bootstrap";

import Cloud26 from "../../../Img/clouds26.png";

import androidapp from "../../../Img/service-icons/mobile-app/android-app.svg";
import iosapp from "../../../Img/service-icons/mobile-app/ios-app.svg";

export default function mobileAppServices() {
  const mobileAppServicesData = [
    {
      id: 1,
      Img: androidapp,
      Title: "Android app development",
      Text: "Tap into the vast Android market with a high-quality app that stands out from the competition. Our Android developers leverage the latest technologies to create responsive, feature-rich apps that elevate your brand.",
    },
    {
      id: 2,
      Img: iosapp,
      Title: "iOS app development",
      Text: "Reach millions of iOS users with a custom-built app tailored to the unique capabilities of the Apple ecosystem. From iPhones to iPads, we create seamless experiences that delight users and drive results.",
    },
  ];

  return (
    <>
      <section className="why-graphic-design bg-blue-lighteen section-padding-skew section-bg-skew service-bg-1">
        <Container>
          <Row className="mb-5">
            <div className="col-lg-12">
              <div className="sec-title">
                <img className="cloud" src={Cloud26} alt="cloud" />
                <h2>
                  <span>Mobile app services</span>
                </h2>
              </div>
            </div>
          </Row>
          <Row className="g-3 teammembers-wrap">
            {mobileAppServicesData.map((mobileAppServices) => (
              <div
                className="col-lg-6 col-md-6 col-sm-12 col-xs-12"
                key={mobileAppServices.id}
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                <div className="crumina-module crumina-teammembers-item icon_box_left">
                  <div className="teammembers-thumb">
                    <img
                      src={mobileAppServices.Img}
                      alt={mobileAppServices.Title}
                    />
                  </div>

                  <div className="teammember-content">
                    <div className="teammembers-item-prof">
                      {mobileAppServices.Title}
                    </div>

                    <p>{mobileAppServices.Text}</p>
                  </div>
                </div>
              </div>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
}
