import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper/modules";

import WebConfig from "../../WebConfig";

const HomeClients = () => {
  console.log("home");
  const ApiUrl = WebConfig.ApiUrl;
  const [Clients, setClients] = useState([]);

  useEffect(() => {
    const getClientsData = async () => {
      const res = await fetch(`${ApiUrl}/get_clients.php`);
      const getdata = await res.json();
      setClients(getdata.clients_list);
    };
    console.log("api");
    getClientsData();
  }, [ApiUrl]);

  return (
    <section
      className="crumina-module crumina-module-slider crumina-clients navigation-center-both-sides background-contain"
      style={{ backgroundColor: "#ecf5fe", padding: "100px 0px" }}
    >
      <div className="container">
        <div className="row">
          <Swiper
            spaceBetween={30}
            navigation={{
              nextEl: ".btn-next",
              prevEl: ".btn-prev",
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Navigation]}
            className="swiper-container swiper-swiper-unique-id-2 initialized swiper-container-horizontal"
            slidesPerView={2}
            speed={1000}
            breakpoints={{
              640: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 4,
              },
            }}
          >
            {Clients.map((Client) => (
              <SwiperSlide key={Client.clients_id}>
                <div className="clients-images-carousel">
                  <img src={Client.clients_img} alt={Client.clients_name} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        {/* Prev next buttons */}
        <div className="btn-prev">
          <i className="utouch-icon fa fa-chevron-left"></i>
        </div>

        <div className="btn-next">
          <i className="utouch-icon fa fa-chevron-right"></i>
        </div>
      </div>
    </section>
  );
};

export default HomeClients;
