import React from "react";
import SolutionBanner from "./SolutionBanner";
import SolutionContent from "./SolutionContent";
import WebSolution from "./WebSolution";
import MobileSolution from "./MobileSolution";
import OnlineMarketing from "./OnlineMarketing";
import { CTASolution } from "./CTASolution";
import "../Solution.css";

export default function Solution() {
  return (
    <>
      <SolutionBanner />
      <SolutionContent />
      <WebSolution />
      <MobileSolution />
      <OnlineMarketing />
      <CTASolution />
    </>
  );
}
