import React from "react";
import WebConfig from "../WebConfig";
import { Link } from "react-router-dom";

export const Services = () => {
  const webUrl = WebConfig.ConfigUrl;

  const servicesMenuList = [
    {
      id: 1,
      title: "Web design development",
      link: `${webUrl}service/web-design-development`,
    },
    {
      id: 2,
      title: "Web application development",
      link: `${webUrl}service/web-application-development`,
    },
    {
      id: 3,
      title: "Mobile app development",
      link: `${webUrl}service/mobile-app-development`,
    },
    {
      id: 4,
      title: "Digital marketing",
      link: `${webUrl}service/digital-marketing`,
    },
    {
      id: 5,
      title: "Graphic design",
      link: `${webUrl}service/graphic-design`,
    },
    {
      id: 6,
      title: "Dedicated hiring",
      link: `${webUrl}service/dedicated-hiring`,
    },
    {
      id: 7,
      title: "Domains & hosting",
      link: `${webUrl}service/domains-hosting`,
    },
    // Add more testimonial objects as needed
  ];
  return (
    <div className="widget w-list">
      <h5 className="widget-title">Services</h5>
      <ul className="list list--primary ">
        {servicesMenuList.map((servicesMenu) => (
          <li key={servicesMenu.id}>
            <Link to={servicesMenu.link}>{servicesMenu.title}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
