import React from "react";
import AriviyalContent from "./AriviyalContent";
import AriviyalFeatures from "./AriviyalFeatures";
import AriviyalScreenshots from "./AriviyalScreenshots";
import "../../Casestudy/Casestudy.css";
import AriviyalBanner from "./AriviyalBanner";

export default function Ariviyal() {
  return (
    <>
      <AriviyalBanner />
      <AriviyalContent />
      <AriviyalFeatures />
      <AriviyalScreenshots />
    </>
  );
}
