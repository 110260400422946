import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import Modal from "react-bootstrap/Modal";

export default function VairraDiamondsScreenshots() {
  const [show, setShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleShowModal = (screenshots) => {
    setSelectedItem(screenshots);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setSelectedItem(null);
  };
  const screenshotsitems = [
    {
      id: 1,
      img: "https://berrydashboard.io/assets/slider-light-5-CgjgeexX.png",
    },
    {
      id: 2,
      img: "https://berrydashboard.io/assets/slider-light-1-DliTUNqz.png",
    },
    {
      id: 3,
      img: "https://berrydashboard.io/assets/slider-light-2-CktDTGMJ.png",
    },
    {
      id: 4,
      img: "https://berrydashboard.io/assets/slider-light-4-D0InGprx.png",
    },
    {
      id: 5,
      img: "https://berrydashboard.io/assets/slider-light-6-DvQggOnO.png",
    },
    {
      id: 6,
      img: "https://berrydashboard.io/assets/slider-light-3-DP_FfJEV.png",
    },
    {
      id: 7,
      img: "https://berrydashboard.io/assets/slider-light-6-DvQggOnO.png",
    },
    // Add more testimonial objects as needed
  ];

  return (
    <>
      <section className="section-padding align-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-lg-offset-3 col-md-12 col-sm-12 col-xs-12 mb30">
              <div className="crumina-module crumina-heading">
                <h6 className="heading-sup-title">Screenshots</h6>
                <h2 className="heading-title">
                  Beautiful Design and Comfortable User Interface
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div className="crumina-module crumina-module-slider slider--full-width screenshots-slider-style1 navigation-center-both-sides casestudy-screenshots">
          <Swiper
            spaceBetween={0}
            navigation={{
              nextEl: ".btn-next",
              prevEl: ".btn-prev",
            }}
            // pagination={true}
            modules={[Navigation, Pagination]}
            loop={true}
            centeredSlides={true}
            className="swiper-container pagination-bottom swiper-swiper-unique-id-1 initialized swiper-container-horizontal"
            id="swiper-unique-id-1"
            slidesPerView={1} // Default value for small screens
            breakpoints={{
              640: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
          >
            {screenshotsitems.map((screenshots) => (
              <SwiperSlide key={screenshots.id}>
                <div className="screenshot-item">
                  <div
                    className="js-zoom-image"
                    onClick={() => handleShowModal(screenshots)}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={screenshots.img}
                      alt="img"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </SwiperSlide>
            ))}
            {/* <div className="swiper-pagination"></div> */}
          </Swiper>
          <div className="btn-prev">
            <i className="utouch-icon fa fa-chevron-left"></i>
          </div>

          <div className="btn-next">
            <i className="utouch-icon fa fa-chevron-right"></i>
          </div>
        </div>
      </section>

      <Modal
        show={show}
        onHide={handleClose}
        className="case-study-model-screenshoot screenshoot-for-web"
        centered
      >
        <Modal.Header
          style={{ borderBottom: "none" }}
          closeButton
          data-bs-theme="dark"
        ></Modal.Header>

        <Modal.Body>
          {selectedItem && selectedItem.img && (
            <div className="text-center w-100">
              <img
                src={selectedItem.img}
                alt="Expand"
                className="expand js-zoom-image img-fluid"
              />
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
