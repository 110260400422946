import React from "react";
import { Container, Row } from "react-bootstrap";

import icon1 from "../../../Img/icons/creative-website.png";
import icon2 from "../../../Img/icons/wordpress-website.png";
import icon3 from "../../../Img/icons/e-com-website.png";
import icon4 from "../../../Img/icons/cms-website.png";

export default function WebSolution() {
  const solutionData = [
    {
      Img: icon1,
      Title: "Creative Website",
      Text: "At Tulips Media, we believe in crafting websites that are not just visually stunning but also strategically designed to drive results. Our team of creative experts merges cutting-edge design with intuitive user experiences to ensure your website captivates visitors and converts them into loyal customers. ",
    },
    {
      Img: icon2,
      Title: "WordPress Website",
      Text: "At Tulips Media, we believe in crafting websites that are not just visually stunning but also strategically designed to drive results. Our team of creative experts merges cutting-edge design with intuitive user experiences to ensure your website captivates visitors and converts them into loyal customers. ",
    },
    {
      Img: icon3,
      Title: "E-Commerce Website",
      Text: "At Tulips Media, we believe in crafting websites that are not just visually stunning but also strategically designed to drive results. Our team of creative experts merges cutting-edge design with intuitive user experiences to ensure your website captivates visitors and converts them into loyal customers. ",
    },
    {
      Img: icon4,
      Title: "CMS Development",
      Text: "At Tulips Media, we believe in crafting websites that are not just visually stunning but also strategically designed to drive results. Our team of creative experts merges cutting-edge design with intuitive user experiences to ensure your website captivates visitors and converts them into loyal customers. ",
    },
  ];
  return (
    <>
      <section className="section-bg-skew bg-blue-lighteen web-solution">
        <Container>
          <Row>
            <div className="col-lg-12">
              <div className="crumina-module crumina-module-img-bottom">
                <div className="crumina-module crumina-heading position-relative">
                  <div className="heading-left clum-6 cloude-white">
                    <h2 className="heading-title">
                      <span>Web Solution</span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </Row>
          <Row className="g-5">
            {solutionData.map((solution, index) => (
              <div
                className="col-lg-6 col-md-6 col-sm-12 col-xs-12"
                key={index}
              >
                <div className="crumina-module crumina-info-box info-box--standard-hover">
                  <div className="info-box-image">
                    <img
                      className="utouch-icon img-fluid"
                      alt="Solution"
                      src={solution.Img}
                    />
                  </div>
                  <div className="info-box-content">
                    <h5 className="info-box-title">{solution.Title}</h5>
                    <p className="info-box-text">{solution.Text}</p>
                  </div>
                </div>
              </div>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
}
