import React from "react";
import CasestudyList from "./CasestudyList";
import "./Casestudy.css";
import "../../Css/Custom.css";
import CasestudyBanner from "./CasestudyBanner";
import CasestudyContent from "./CasestudyContent";
import { CasesutyCta } from "./CasesutyCta";
import CasestudyClients from "./CasestudyClients";
import { Helmet } from "react-helmet-async";
import SuccessStories from "./SuccessStories";

export default function Casestudy() {
  return (
    <>
      <Helmet>
        <title>Case Study | Tulips Media</title>
        <meta
          name="description"
          content="creative and technological solution agency"
        />
      </Helmet>
      <CasestudyBanner />
      <CasestudyContent />
      <SuccessStories />
      <CasestudyList />
      <CasestudyClients />
      <CasesutyCta />
    </>
  );
}
