import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Parallax, Navigation, EffectFade } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import banner_1_bg from "../../Img/slider-img/banner_1_bg.jpg";
import banner_1_img from "../../Img/slider-img/banner_1_img.png";
import banner_3_bg from "../../Img/slider-img/banner_3_bg.jpg";
import banner_3_img from "../../Img/slider-img/banner_3_img.png";
import WebConfig from "../../WebConfig";

export default function HomeSlider() {
  const webUrl = WebConfig.ConfigUrl;

  return (
    <section className="home_main_slider crumina-module crumina-module-slider container-full-width navigation-center-both-sides">
      <Swiper
        speed={600}
        effect={"fade"}
        loop={true}
        parallax={true}
        navigation={true}
        modules={[EffectFade, Parallax, Navigation]}
        className="mySwiper swiper-container main-slider navigation-center-both-sides"
        data-effect="fade"
      >
        <div className="swiper-wrapper">
          {/*  Slider 1 */}
          <SwiperSlide
            slot="container-start"
            className="parallax-bg  swiper-slide main-slider-bg-light banner-1-bg"
            data-swiper-parallax="-23%"
            style={{ backgroundImage: `url(${banner_1_bg})` }}
          >
            <Container>
              <Row className="table-cell">
                {/* <div className="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-12 col-sm-offset-0 col-xs-12"> */}
                <div className="col-lg-8 col-lg-offset-2 col-md-12 col-sm-12 col-sm-offset-0 col-xs-12">
                  <div className="slider-content align-center">
                    <h6
                      className="slider-content-text"
                      data-swiper-parallax="-200"
                    >
                      We create possibilities to suit the
                    </h6>
                    <h1
                      className="slider-content-title with-decoration"
                      data-swiper-parallax="-100"
                    >
                      <div className="line-left">
                        <span className="lf-1"></span>
                        <span className="lf-2"></span>
                        <span className="lf-3"></span>
                      </div>
                      Future Technology that walks through space of business!
                      <div className="line-right">
                        <span className="lr-1"></span>
                        <span className="lr-2"></span>
                        <span className="lr-3"></span>
                      </div>
                    </h1>
                    <div
                      className="main-slider-btn-wrap"
                      data-swiper-parallax="-300"
                    >
                      <Link
                        to={`${webUrl}about-us`}
                        className="btn btn--yellow hvr-bg-blue  hvr-white"
                      >
                        Get Started Now
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div
                    className="slider-thumb"
                    data-swiper-parallax="-400"
                    data-swiper-parallax-duration="600"
                  >
                    <img
                      src={banner_1_img}
                      alt="slider"
                      className="slider-1-inner img-fluid banner_1_img"
                    />
                  </div>
                </div>
              </Row>
            </Container>
          </SwiperSlide>

          {/* Slider 3 */}
          <SwiperSlide
            slot="container-start"
            className="parallax-bg  swiper-slide thumb-left main-slider-bg-light"
            data-swiper-parallax="-23%"
            style={{ backgroundImage: `url(${banner_3_bg})` }}
          >
            <Container className="table full-height">
              <Row className="table-cell">
                <div className="col-lg-6 col-sm-12 table-cell">
                  <div className="slider-content align-both bg-transparent">
                    <h6
                      className="slider-content-text"
                      data-swiper-parallax="-200"
                    >
                      We strategize business concepts that
                    </h6>

                    <h2
                      className="slider-content-title"
                      data-swiper-parallax="-100"
                    >
                      Stage your brand to audience applause!
                    </h2>

                    <div
                      className="main-slider-btn-wrap"
                      data-swiper-parallax="-300"
                    >
                      <Link
                        to={`${webUrl}about-us`}
                        className="btn btn--dark--blue hvr-bg-yellow hvr-white"
                      >
                        Get Started Now
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-sm-12 table-cell">
                  <div
                    className="slider-thumb bg-transparent"
                    data-swiper-parallax="-300"
                    data-swiper-parallax-duration="500"
                  >
                    <img src={banner_3_img} alt="slider" />
                  </div>
                </div>
              </Row>
            </Container>
          </SwiperSlide>
        </div>
      </Swiper>
    </section>
  );
}
