import React from "react";
import BenzearContent from "./BenzearContent";
import BenzearBanner from "./BenzearBanner";
import BenzearFeatures from "./BenzearFeatures";
import BenzearScreenshots from "./BenzearScreenshots";
import "../../Casestudy/Casestudy.css";

export default function Benzear() {
  return (
    <>
      <BenzearBanner />
      <BenzearContent />
      <BenzearFeatures />
      <BenzearScreenshots />
    </>
  );
}
