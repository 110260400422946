import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import Logo from "../Img/logo/tulips-logo.png";
import WebConfig from "../WebConfig";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useLocation } from "react-router-dom";

function OffcanvasExample() {
  const webUrl = WebConfig.ConfigUrl;
  const location = useLocation();
  const [showDropdown, setShowDropdown] = useState(false);
  const [hideTimeout, setHideTimeout] = useState(null); // State to manage delay before hiding dropdown
  const [activeKey, setActiveKey] = useState("");

  const isPathActive = (path) => {
    return location.pathname === path;
  };

  const dropdownitems = [
    {
      id: 1,
      title: "Web design development",
      link: `${webUrl}service/web-design-development`,
    },
    {
      id: 2,
      title: "Web application development",
      link: `${webUrl}service/web-application-development`,
    },
    {
      id: 3,
      title: "Mobile app development",
      link: `${webUrl}service/mobile-app-development`,
    },
    {
      id: 4,
      title: "Digital marketing",
      link: `${webUrl}service/digital-marketing`,
    },
    {
      id: 5,
      title: "Graphic design",
      link: `${webUrl}service/graphic-design`,
    },
    {
      id: 6,
      title: "Dedicated hiring",
      link: `${webUrl}service/dedicated-hiring`,
    },
    {
      id: 7,
      title: "Domains & Hosting",
      link: `${webUrl}service/domains-hosting`,
    },
    // Add more testimonial objects as needed
  ];

  // Check if any dropdown item is active
  const isDropdownActive = () => {
    return dropdownitems.some((item) => location.pathname === item.link);
  };

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const handleMouseEnter = () => {
    if (!("ontouchstart" in window)) {
      setShowDropdown(true);
    }
    clearTimeout(hideTimeout);
  };

  const handleMouseLeave = () => {
    if (!("ontouchstart" in window)) {
      const timeout = setTimeout(() => {
        setShowDropdown(false);
      }, 300);
      setHideTimeout(timeout);
    }
  };

  return (
    <>
      {["xl"].map((expand) => (
        <Navbar
          key={expand}
          expand={expand}
          bg="white"
          data-bs-theme="white"
          className="theme-menu primary-menu primary-menu-responsive"
        >
          <Container className="d-flex flex-wrap align-items-center">
            <div className="logo">
              <Navbar.Brand href={`${webUrl}`}>
                <img
                  src={Logo}
                  className="img-fuild"
                  alt="Tulips Logo"
                  title="Tulips Media"
                />
              </Navbar.Brand>
            </div>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              className="primary-menu-menu primary-menu-indented scrollable"
            >
              <Offcanvas.Header closeButton></Offcanvas.Header>
              <Offcanvas.Body>
                <Nav
                  className="justify-content-end flex-grow-1"
                  activeKey={activeKey}
                  onSelect={(selectedKey) => setActiveKey(selectedKey)}
                >
                  <Nav.Link
                    href={`${webUrl}`}
                    eventKey="home"
                    active={isPathActive(webUrl)}
                  >
                    Home
                  </Nav.Link>
                  <Nav.Link
                    href={`${webUrl}about-us`}
                    eventKey="about-us"
                    active={isPathActive(`${webUrl}about-us`)}
                  >
                    About Us
                  </Nav.Link>
                  <NavDropdown
                    title="Our Services"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                    show={showDropdown} // Manage the visibility of the Offcanvas
                    onClick={handleDropdownToggle} // Toggle the dropdown state on click
                    onMouseEnter={handleMouseEnter} // Show the Offcanvas on mouse enter
                    onMouseLeave={handleMouseLeave} // Hide the Offcanvas on mouse leave
                    active={isDropdownActive()} // Set active if any service link is active
                  >
                    <div className="sub-menu sub-menu-right drop-up submenu-ul">
                      {dropdownitems.map((dropdownitem) => (
                        <div key={dropdownitem.id} className="submenu-li">
                          <NavDropdown.Item
                            href={`${dropdownitem.link}`}
                            active={isPathActive(dropdownitem.link)}
                          >
                            {dropdownitem.title}
                          </NavDropdown.Item>
                        </div>
                      ))}
                    </div>
                  </NavDropdown>
                  <Nav.Link
                    href={`${webUrl}portfolio`}
                    eventKey="portfolio"
                    active={isPathActive(`${webUrl}portfolio`)}
                  >
                    Portfolio
                  </Nav.Link>
                  {/* <Nav.Link
                    href={`${webUrl}case-study`}
                    eventKey="case-study"
                    active={isPathActive(`${webUrl}case-study`)}
                  >
                    Case Study
                  </Nav.Link> */}
                  <Nav.Link
                    href={`${webUrl}reach-us`}
                    eventKey="reach-us"
                    active={isPathActive(`${webUrl}reach-us`)}
                  >
                    Reach Us
                  </Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default OffcanvasExample;
