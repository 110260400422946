import React from "react";
import { Helmet } from "react-helmet-async";
import MobileAppBanner from "./MobileAppBanner";
import MobileAppProfileContent from "./MobileAppProfileContent";
import MobileAppServices from "./MobileAppServices";
import "../Services.css";
import MobileAppDev from "./MobileAppDev";
import { CallToAction } from "../../Template/CTA/CallToAction";

export default function MobileApp() {
  return (
    <>
      <Helmet>
        <title>Mobile Application Development</title>
        <meta
          name="description"
          content="creative and technological solution agency"
        />
      </Helmet>
      <MobileAppBanner />
      <MobileAppProfileContent />
      <MobileAppServices />
      <MobileAppDev />
      <CallToAction />
    </>
  );
}
