import React, { useState } from "react";
import WebConfig from "../../WebConfig";
import ReCAPTCHA from "react-google-recaptcha";

export const ContactForm = () => {
  const ApiUrl = WebConfig.ApiUrl;
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    subject: "",
    message: "",
    recaptcha: "", // Add recaptcha to the form data
  });
  const [formErrors, setFormErrors] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false); // Track form submission

  // reCAPTCHA site key (replace with your site key)
  const RECAPTCHA_SITE_KEY = "6Lepp1gqAAAAAGFNwPlX8dWRuZ6DtTFWnprA-Ohy";

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setFormErrors({});
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form fields
    const errors = {};
    if (!formData.name) errors.name = "Name is required";
    if (!formData.email) errors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(formData.email))
      errors.email = "Invalid email format";
    if (!formData.number) errors.number = "Mobile number is required";
    if (!formData.recaptcha) errors.recaptcha = "Please verify the captcha"; // Check reCAPTCHA validation

    setFormErrors(errors);

    // If there are no errors, submit the form
    if (Object.keys(errors).length === 0) {
      try {
        const response = await fetch(`${ApiUrl}/send_mail.php`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });

        if (response.ok) {
          console.log("Form submitted successfully");
          setFormSubmitted(true); // Set the form submission success state to true

          // Reset form fields
          setFormData({
            name: "",
            email: "",
            number: "",
            subject: "",
            message: "",
            recaptcha: "",
          });
        } else {
          console.error("Error submitting form");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  // Handle reCAPTCHA verification
  const onRecaptchaChange = (value) => {
    setFormData({
      ...formData,
      recaptcha: value,
    });
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="contact-form form-validate contact-form items-with-border"
        noValidate
      >
        <div className="row">
          {/* Form Fields */}
          <div className="col-lg-6 col-md-6 mb-3">
            <input
              name="name"
              placeholder="Your Name*"
              type="text"
              value={formData.name}
              onChange={handleInputChange}
            />
            {formErrors.name && <div className="error">{formErrors.name}</div>}
          </div>

          <div className="col-lg-6 col-md-6 mb-3">
            <input
              name="email"
              placeholder="Your Email*"
              type="email"
              value={formData.email}
              onChange={handleInputChange}
            />
            {formErrors.email && (
              <div className="error">{formErrors.email}</div>
            )}
          </div>

          <div className="col-lg-6 col-md-6 mb-3">
            <input
              name="number"
              placeholder="Your Mobile Number*"
              type="number"
              value={formData.number}
              onChange={handleInputChange}
            />
            {formErrors.number && (
              <div className="error">{formErrors.number}</div>
            )}
          </div>

          <div className="col-lg-6 col-md-6 mb-3">
            <input
              name="subject"
              placeholder="Your Subject"
              type="text"
              value={formData.subject}
              onChange={handleInputChange}
            />
          </div>

          <div className="col-lg-12 mb-3">
            <textarea
              name="message"
              placeholder="Your Message"
              style={{ height: "160px" }}
              value={formData.message}
              onChange={handleInputChange}
            ></textarea>
          </div>

          <div className="col-lg-12 mb-3">
            <ReCAPTCHA
              sitekey={RECAPTCHA_SITE_KEY}
              onChange={onRecaptchaChange}
            />
            {formErrors.recaptcha && (
              <div className="error">{formErrors.recaptcha}</div>
            )}
          </div>

          <div className="col-lg-12">
            <input
              type="submit"
              className="btn btn--dark--blue hvr-bg-yellow hvr-white submit-btn-blue"
              value="Send a message"
            />
          </div>

          {/* Success Message */}
          {formSubmitted && (
            <div className="col-lg-12 mt-3">
              <div className="success-message">
                Thank you! Your message has been sent successfully.
              </div>
            </div>
          )}
        </div>
      </form>
    </>
  );
};
