import React from "react";

export const ContactAddress = () => {
  return (
    <>
      <div className="crumina-module crumina-heading">
        <h3 className="heading-title">Get in touch with us</h3>
        <div className="heading-text">
          If you got any questions, please do not hesitate to send us a message.
          We reply within 24 hours!
        </div>
      </div>
      <div className="widget w-contacts w-contacts--style2 ">
        <div className="contact-item display-flex">
          <i className="bi bi-geo-alt"></i>
          <span className="info">
            No.206/4, Devarajan Centre, Near Ayyappan Pooja Sangam, Sathya
            Moorthy Road, Ram Nagar, Coimbatore, Tamilnadu, India - 641 009
          </span>
        </div>
        <div className="contact-item display-flex">
          <i className="bi bi-phone"></i>

          <a href="tel:9360366453" className="info">
            +91 93603 66453
          </a>
        </div>
        <div className="contact-item display-flex">
          <i className="bi bi-envelope-at"></i>
          <a href="mailto:hello@tulipsmedia.com" className="info">
            hello@tulipsmedia.com
          </a>
        </div>
      </div>
    </>
  );
};
