import React from "react";
import { Link } from "react-router-dom";

export default function TopBar() {
  return (
    <>
      <div className="header-lines-decoration">
        <span className="bg-secondary-color"></span>
        <span className="bg-blue"></span>
        <span className="bg-blue-light"></span>
        <span className="bg-orange-light"></span>
        <span className="bg-red"></span>
        <span className="bg-green"></span>
        <span className="bg-secondary-color"></span>
      </div>

      <div className="top-bar top-bar-dark top-bar-sm">
        <div className="container">
          <div className="top-bar-contact">
            <div className="contact-item">
              <span>Monday - Friday 09:30 - 06:30</span>
            </div>

            <div className="contact-item">
              <i className="bi bi-phone"></i>
              <Link to="tel:+919360366453">+91 93603 66453</Link>
            </div>

            <div className="contact-item">
              <i className="bi bi-envelope-open"></i>
              <Link to="mailto:hello@tulipsmedia.com">
                hello@tulipsmedia.com
              </Link>
            </div>
          </div>

          <div className="follow_us">
            <span>Follow us:</span>
            <div className="socials">
              <Link
                to="https://www.facebook.com/tulipsmedia"
                target="_blank"
                className="social__item"
              >
                <i className="bi bi-facebook utouch-icon"></i>
              </Link>
              <Link
                to="https://www.instagram.com/tulipsmediaindia/"
                className="social__item"
                target="_blank"
              >
                <i className="bi bi-instagram utouch-icon"></i>
              </Link>
              <Link
                to="https://www.linkedin.com/company/71587210/"
                className="social__item"
                target="_blank"
              >
                <i className="bi bi-linkedin utouch-icon"></i>
              </Link>
            </div>
          </div>

          <Link to="#" className="top-bar-close" id="top-bar-close-js">
            <svg className="utouch-icon utouch-icon-cancel-1">
              <use xlinkHref="#utouch-icon-cancel-1"></use>
            </svg>
          </Link>
        </div>
      </div>
    </>
  );
}
