import React from "react";
import { Link } from "react-router-dom";

export const Contact = () => {
  return (
    <div className="widget w-list">
      <h5 className="widget-title">Contact with us</h5>
      <div>
        <div className="contact-item display-flex gap-2 align-items-start">
          <i className="bi bi-geo-alt text-yellow"></i>
          <span className="info">
            No.206/4, Devarajan Centre, Near Ayyappan Pooja Sangam, Sathya
            Moorthy Road, Ram Nagar, Coimbatore, Tamilnadu, India - 641 009.
          </span>
        </div>
        <div className="contact-item display-flex gap-2 align-items-start">
          <i className="bi bi-phone text-yellow"></i>
          <span className="info">
            <Link to="tel:+919360366453">+91 93603 66453</Link>
          </span>
        </div>
        <div className="contact-item display-flex gap-2 align-items-start">
          <i className="bi bi-envelope text-yellow"></i>
          <span className="info">
            <Link to="mailto:hello@tulipsmedia.com">hello@tulipsmedia.com</Link>
          </span>
        </div>
      </div>

      <div className="widget w-follow mt-3">
        <ul>
          <li>Follow on : </li>
          <li>
            <Link to="https://www.facebook.com/tulipsmedia" target="_blank">
              <i className="bi bi-facebook"></i>
            </Link>
          </li>
          <li>
            <Link
              to="https://www.instagram.com/tulipsmediaindia/"
              target="_blank"
            >
              <i className="bi bi-instagram"></i>
            </Link>
          </li>
          <li>
            <Link
              to="https://www.linkedin.com/company/71587210/"
              target="_blank"
            >
              <i className="bi bi-linkedin"></i>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};
