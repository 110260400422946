import React from "react";
import { Container, Row } from "react-bootstrap";

import img1 from "../../Img/clients/logo-1.png";
import img2 from "../../Img/clients/logo-2.png";
import img3 from "../../Img/clients/logo-3.png";
import img4 from "../../Img/clients/logo-4.png";
import img5 from "../../Img/clients/logo-5.png";
import img6 from "../../Img/clients/logo-6.png";
import Cloud8 from "../../Img/clouds8.png";

export default function CasestudyClients() {
  return (
    <>
      <section className="pt100 pb100 bg-blue-lighteen ">
        <Container>
          <Row className="mb-5">
            <div className="col-lg-12">
              <div className="sec-title">
                <img className="cloud" src={Cloud8} alt="cloud" />
                <h2>
                  <span>Trusted by</span>
                </h2>
              </div>
            </div>
          </Row>
          <Row className="g-5">
            <div className="col-lg-3 col-md-3 col-sm-4 col-xs-6">
              <div className="trusteby-list">
                <img src={img1} className="img-fluid" alt="Logo" />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-4 col-xs-6">
              <div className="trusteby-list">
                <img src={img2} className="img-fluid" alt="Logo" />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-4 col-xs-6">
              <div className="trusteby-list">
                <img src={img3} className="img-fluid" alt="Logo" />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-4 col-xs-6">
              <div className="trusteby-list">
                <img src={img4} className="img-fluid" alt="Logo" />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-4 col-xs-6">
              <div className="trusteby-list">
                <img src={img5} className="img-fluid" alt="Logo" />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-4 col-xs-6">
              <div className="trusteby-list">
                <img src={img6} className="img-fluid" alt="Logo" />
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
}
