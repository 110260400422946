import React from "react";
import { Link } from "react-router-dom";

export const Copyrights = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="sub-footer">
      <Link
        className="back-to-top icon-link icon-link-hover"
        to="#"
        style={{ "--bs-icon-link-transform": "translate3d(0, -.125rem, 0)" }}
      >
        <i className="fa fa-chevron-up fw-bold"></i>
      </Link>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <span>
              Copyright © {currentYear} | All Rights Reserved{" "}
              <Link
                to="https://tulipsmedia.com/"
                target="_blank"
                className="sub-footer__link"
              >
                Tulips Media
              </Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
