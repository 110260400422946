import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import underlinewhite from "../../../Img/title-underline-white.png";
import { Link } from "react-router-dom";
import AOS from "aos";
import WebConfig from "../../../WebConfig";
import "./CallToAction.css";

export const CallToAction = () => {
  const webUrl = WebConfig.ConfigUrl;

  useEffect(() => {
    AOS.init({
      once: true,
      offset: 120,
    });
  }, []);
  return (
    <>
      <section className="cta-bg background-cover align-center dark">
        <Container>
          <Row>
            <div className="col-lg-9 col-lg-offset-2 col-md-9 col-md-offset-2 col-sm-12 col-sm-offset-0 col-xs-12">
              <div
                className="crumina-module crumina-heading mb-0"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                <h5 className="heading-title">Get in touch with us</h5>
                <h2 className="mt-1">
                  Elevate ideas through innovative design
                </h2>
                <img
                  src={underlinewhite}
                  className="img-fluid"
                  alt="underline"
                />
              </div>
            </div>
          </Row>
          <Row>
            <div
              className="col-lg-9 col-lg-offset-2 col-md-9 col-md-offset-2 col-sm-12 col-sm-offset-0 col-xs-12"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="500"
            >
              <Link
                to={`${webUrl}reach-us`}
                className="btn btn--blue hvr-bg-yellow hvr-white mt-5"
              >
                Request a Quote
              </Link>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
};
