import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import Cloud8 from "../../../Img/clouds8.png";
import AOS from "aos";

export default function WebDesignProfileContent() {
  useEffect(() => {
    AOS.init({
      once: true,
      offset: 120,
    });
  }, []);
  return (
    <>
      <section className="content-block">
        <Container>
          <Row>
            <div className="col-lg-12">
              <div
                className="sec-title"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                <img className="cloud" src={Cloud8} alt="cloud" />
                <h2>
                  <span>Web design and development company in India</span>
                </h2>
              </div>
            </div>
          </Row>
          <Row>
            <div className="col-lg-12">
              <div
                className="heading-text"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                At Tulips Media, we believe that a well-crafted website is the
                cornerstone of a successful digital presence. Our comprehensive
                Web Design & Development services are tailored to meet the
                unique needs of your business, ensuring an engaging,
                user-friendly, and visually appealing experience for your
                audience.
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
}
