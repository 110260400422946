import React, { useEffect } from "react";
import "./Css/Custom.css";
import "./Css/blocks.css";
import "./Css/widgets.css";
import "./Css/theme-style.css";
import "./Css/Style.css";
import "./Css/Media.css";
import Home from "./Component/Home/Home";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { Routes, Route, useLocation } from "react-router-dom";
import { About } from "./Component/About/About";
import WebConfig from "./WebConfig";
import { Contact } from "./Component/ContactUs/Contact";
import Solution from "./Component/Solution/DigitalSolution/Solution";
import Casestudy from "./Component/Casestudy/Casestudy";
import Portfolio from "./Component/Portfolio/Portfolio";
import DesignSolutions from "./Component/Solution/DesignSolutions/DesignSolutions";
import "aos/dist/aos.css";
import WebDesign from "./Component/Service/WebDesignDevelopment/WebDesign";
import WebApp from "./Component/Service/WebApplicationDevelopment/WebApp";
import MobileApp from "./Component/Service/MobileAppDevelopment/MobileApp";
import DigitalMarketing from "./Component/Service/DigitalMarketing/DigitalMarketing";
import GraphicDesign from "./Component/Service/GraphicDesign/GraphicDesign";
import DedicatedHiring from "./Component/Service/DedicatedHiring/DedicatedHiring";
import VairraDiamonds from "./Component/CasestudyDetail/VairraDiamonds/VairraDiamonds";
import STCourier from "./Component/CasestudyDetail/STCourier/STCourier";
import Ariviyal from "./Component/CasestudyDetail/AriviyalPublishing/Ariviyal";
import Benzear from "./Component/CasestudyDetail/Benzear/Benzear";
import SMTravels from "./Component/CasestudyDetail/SMTravels/SMTravels";
import DomainsHosting from "./Component/Service/DomainsHosting/DomainsHosting";

function App() {
  const webUrl = WebConfig.ConfigUrl;
  console.log(webUrl);
  const location = useLocation();
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    setTimeout(() => {
      scrollToTop();
    }, 100);
  }, [location]);
  return (
    <>
      <Header />
      <div className="content-wrapper">
        <Routes>
          <Route exact path={`${webUrl}`} element={<Home />} />
          <Route path={`${webUrl}about-us`} element={<About />} />
          <Route path={`${webUrl}reach-us`} element={<Contact />} />
          <Route path={`${webUrl}digital-solution`} element={<Solution />} />
          <Route
            path={`${webUrl}design-solution`}
            element={<DesignSolutions />}
          />
          <Route
            path={`${webUrl}service/web-design-development`}
            element={<WebDesign />}
          />
          <Route
            path={`${webUrl}service/web-application-development`}
            element={<WebApp />}
          />
          <Route
            path={`${webUrl}service/mobile-app-development`}
            element={<MobileApp />}
          />
          <Route
            path={`${webUrl}service/digital-marketing`}
            element={<DigitalMarketing />}
          />
          <Route
            path={`${webUrl}service/graphic-design`}
            element={<GraphicDesign />}
          />
          <Route
            path={`${webUrl}service/dedicated-hiring`}
            element={<DedicatedHiring />}
          />
          <Route
            path={`${webUrl}service/domains-hosting`}
            element={<DomainsHosting />}
          />
          <Route path={`${webUrl}portfolio`} element={<Portfolio />} />
          <Route path={`${webUrl}case-study`} element={<Casestudy />} />

          <Route
            path={`${webUrl}case-study/vairra-diamonds`}
            element={<VairraDiamonds />}
          />
          <Route
            path={`${webUrl}case-study/st-courier`}
            element={<STCourier />}
          />
          <Route path={`${webUrl}case-study/ariviyal`} element={<Ariviyal />} />
          <Route path={`${webUrl}case-study/benzear`} element={<Benzear />} />

          <Route
            path={`${webUrl}case-study/sm-travels`}
            element={<SMTravels />}
          />
        </Routes>
      </div>
      <Footer />
    </>
  );
}

export default App;
