import React from "react";
import { Container, Row } from "react-bootstrap";

import mobilesolutionicon1 from "../../../Img/icons/android-dev.png";
import mobilesolutionicon2 from "../../../Img/icons/ios-dev.png";

export default function MobileSolution() {
  const mobilesolutionData = [
    {
      Img: mobilesolutionicon1,
      Title: "Android Development",
      Text: "At Tulips Media, we believe in crafting websites that are not just visually stunning but also strategically designed to drive results. Our team of creative experts merges cutting-edge design with intuitive user experiences to ensure your website captivates visitors and converts them into loyal customers. ",
    },
    {
      Img: mobilesolutionicon2,
      Title: "IOS Development",
      Text: "At Tulips Media, we believe in crafting websites that are not just visually stunning but also strategically designed to drive results. Our team of creative experts merges cutting-edge design with intuitive user experiences to ensure your website captivates visitors and converts them into loyal customers. ",
    },
  ];

  return (
    <>
      <section className="mobile-solution-bg">
        <Container>
          <Row>
            <div className="col-lg-12">
              <div className="crumina-module crumina-module-img-bottom">
                <div className="crumina-module crumina-heading position-relative">
                  <div className="heading-left clum-6 mbl-slu-cloude">
                    <h2 className="heading-title text-white">
                      <span>Mobile Solution</span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </Row>
          <Row className="g-5">
            {mobilesolutionData.map((solution, index) => (
              <div
                className="col-lg-6 col-md-6 col-sm-12 col-xs-12"
                key={index}
              >
                <div className="crumina-module crumina-info-box info-box--standard-hover">
                  <div className="info-box-image">
                    <img
                      className="utouch-icon img-fluid"
                      alt="Solution"
                      src={solution.Img}
                    />
                  </div>
                  <div className="info-box-content text-white">
                    <h5 className="info-box-title">{solution.Title}</h5>
                    <p className="info-box-text">{solution.Text}</p>
                  </div>
                </div>
              </div>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
}
