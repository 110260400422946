import React from "react";
import VairraDiamondsContent from "./VairraDiamondsContent";
import VairraDiamondsBanner from "./VairraDiamondsBanner";
import VairraDiamondsFeatures from "./VairraDiamondsFeatures";
import VairraDiamondsScreenshots from "./VairraDiamondsScreenshots";
import "../../Casestudy/Casestudy.css";

export default function VairraDiamonds() {
  return (
    <>
      <VairraDiamondsBanner />
      <VairraDiamondsContent />
      <VairraDiamondsFeatures />
      <VairraDiamondsScreenshots />
    </>
  );
}
