import React from "react";
import { Container, Row } from "react-bootstrap";
import Cloud26 from "../../../Img/clouds26.png";

import socialmedia from "../../../Img/service-icons/digital-marketing/social-media.svg";
import ppc from "../../../Img/service-icons/digital-marketing/ppc.svg";
import contentmarketing from "../../../Img/service-icons/digital-marketing/content-marketing.svg";
import emailmarketing from "../../../Img/service-icons/digital-marketing/email-marketing.svg";
import googleads from "../../../Img/service-icons/digital-marketing/google-ads.svg";
import smsmarketing from "../../../Img/service-icons/digital-marketing/sms-marketing.svg";
import seo from "../../../Img/service-icons/digital-marketing/seo.svg";
export default function DigitalMarketingServices() {
  const digitalMarketingServicesData = [
    {
      id: 1,
      Img: socialmedia,
      Title: "Social media management & marketing",
      Text: "Harness the power of social media to connect with your audience, build brand awareness, and drive conversions. From creating compelling content to managing ad campaigns, we help you leverage platforms like Facebook, Instagram, Twitter, and LinkedIn to engage with your audience and grow in the global market.",
    },
    {
      id: 2,
      Img: ppc,
      Title: "Pay-Per-Click(PPC) advertising",
      Text: "Accelerate your online growth with targeted PPC campaigns that drive instant traffic to your website. Our team creates and optimizes ads on platforms like Google Ads and social media channels, ensuring maximum reach, relevance, and ROI for your advertising budget.",
    },
    {
      id: 3,
      Img: contentmarketing,
      Title: "Content marketing",
      Text: "Content is king in the digital world, and we're here to help you reign supreme. Our content marketing services include creating high-quality blog posts, articles, infographics, videos, and more that resonate with your audience, drive traffic to your website, and establish you as a thought leader in your industry.",
    },
    {
      id: 4,
      Img: emailmarketing,
      Title: "E-mail marketing",
      Text: "Stay top-of-mind with your audience and nurture leads through personalized email marketing campaigns. From designing eye-catching templates to segmenting your email list and analyzing campaign performance, we help you deliver targeted messages that drive engagement and conversions.",
    },
    {
      id: 5,
      Img: googleads,
      Title: "Google ads",
      Text: "We offer comprehensive support for Google Ads to help you maximise your online visibility and drive targeted traffic to your business. Our team of certified specialists collaborates with you to develop tailored ad campaigns that align with your goals and budget. From keyword research and ad creation to performance tracking and optimization, we ensure every aspect of your Google Ads strategy is finely tuned for success. With our data-driven approach, we provide insightful analytics to refine your campaigns continuously, helping you achieve the best possible return on investment. ",
    },
    {
      id: 6,
      Img: smsmarketing,
      Title: "SMS marketing",
      Text: "SMS marketing solutions that connect you directly with your audience in a timely and impactful way. Our team helps you design and implement targeted SMS campaigns that drive engagement, boost sales, and enhance customer loyalty. From crafting compelling messages to managing opt-in lists and compliance, we handle every aspect of your SMS marketing strategy. With real-time analytics and performance tracking, we provide insights to optimize your campaigns and ensure maximum effectiveness",
    },
    {
      id: 7,
      Img: seo,
      Title: "Search Engine Optimization (SEO)",
      Text: "Our team of SEO experts is dedicated to optimizing your website to rank higher in search engine results pages (SERPs). Through strategic keyword research, on-page optimization, and link building, we improve your website's visibility and organic traffic, ensuring that your target audience can easily find you online.",
    },
  ];

  return (
    <>
      <section className="why-graphic-design section-padding bg-blue-lighteen">
        <Container>
          <Row className="mb-5">
            <div className="col-lg-12">
              <div className="sec-title">
                <img className="cloud" src={Cloud26} alt="cloud" />
                <h2>
                  <span>Digital marketing services</span>
                </h2>
              </div>
            </div>
          </Row>
          <Row className="g-3 teammembers-wrap">
            {digitalMarketingServicesData.map((digitalServicesData) => (
              <div
                className="col-lg-6 col-md-6 col-sm-12 col-xs-12"
                key={digitalServicesData.id}
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                <div className="crumina-module crumina-teammembers-item icon_box_left">
                  <div className="teammembers-thumb">
                    <img
                      src={digitalServicesData.Img}
                      alt={digitalServicesData.Title}
                    />
                  </div>

                  <div className="teammember-content">
                    <div className="teammembers-item-prof">
                      {digitalServicesData.Title}
                    </div>

                    <p>{digitalServicesData.Text}</p>
                  </div>
                </div>
              </div>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
}
