import React from "react";
import { Helmet } from "react-helmet-async";
import WebDesignBanner from "./WebDesignBanner";
import WebDesignProfileContent from "./WebDesignProfileContent";
import WebDesignServices from "./WebDesignServices";
import "../Services.css";
import WebDesignDev from "./WebDesignDev";
import { CallToAction } from "../../Template/CTA/CallToAction";
import { TechnologyStack } from "./TechnologyStack";

export default function WebDesign() {
  return (
    <>
      <Helmet>
        <title>Web Design Development</title>
        <meta
          name="description"
          content="creative and technological solution agency"
        />
      </Helmet>
      <WebDesignBanner />
      <WebDesignProfileContent />
      <WebDesignServices />
      <WebDesignDev />
      <TechnologyStack />
      <CallToAction />
    </>
  );
}
