import React from "react";
import { Helmet } from "react-helmet-async";
import DedicatedHiringBanner from "./DedicatedHiringBanner";
import DedicatedHiringProfileContent from "./DedicatedHiringProfileContent";

import "../Services.css";
import { CallToAction } from "../../Template/CTA/CallToAction";
import WhyChooseDedicatedHiring from "./WhyChooseDedicatedHiring";
import { DedicatedHiringProcess } from "./DedicatedHiringProcess";
import WhyuDedicatedHiring from "./WhyuDedicatedHiring";

export default function DedicatedHiring() {
  return (
    <>
      <Helmet>
        <title>Dedicated Hiring</title>
        <meta
          name="description"
          content="creative and technological solution agency"
        />
      </Helmet>
      <DedicatedHiringBanner />
      <DedicatedHiringProfileContent />
      <WhyChooseDedicatedHiring />
      <DedicatedHiringProcess />
      <WhyuDedicatedHiring />
      <CallToAction />
    </>
  );
}
