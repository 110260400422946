import React from "react";
import { Helmet } from "react-helmet-async";
import GraphicDesignBanner from "./GraphicDesignBanner";
import GraphicDesignProfileContent from "./GraphicDesignProfileContent";
import WhyGraphicDesign from "./WhyGraphicDesign";
import "../Services.css";
import { CallToAction } from "../../Template/CTA/CallToAction";
import GraphicDesignServices from "./GraphicDesignServices";
import { GraphicDesignProcess } from "./GraphicDesignProcess";
import GraphicDesignWhyChoose from "./GraphicDesignWhyChoose ";

export default function GraphicDesign() {
  return (
    <>
      <Helmet>
        <title>Graphic Design</title>
        <meta
          name="description"
          content="creative and technological solution agency"
        />
      </Helmet>
      <GraphicDesignBanner />
      <GraphicDesignProfileContent />
      <WhyGraphicDesign />
      <GraphicDesignServices />
      <GraphicDesignProcess />
      <GraphicDesignWhyChoose />
      <CallToAction />
    </>
  );
}
