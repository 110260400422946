import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import Cloud26 from "../../Img/clouds26.png";
import AOS from "aos";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

import arrowsm from "../../Img/arrow/arrow-sm-1.png";
import arrowlg from "../../Img/arrow/arrow-lg-1.png";
import dotarrow from "../../Img/arrow/dot-arrow-svgrepo-com.png";

import understanding from "../../Img/how-we-work/understanding-your-needs.png";
import planningstrategy from "../../Img/how-we-work/planning-strategy.png";
import cultivating from "../../Img/how-we-work/cultivating-creativity.png";
import nurturinggrowth from "../../Img/how-we-work/nurturing-growth.png";
import harvesting from "../../Img/how-we-work/harvesting-results.png";
import cultivatingrelationships from "../../Img/how-we-work/cultivating-relationships.png";

export const HowWeWork3 = () => {
  useEffect(() => {
    AOS.init({
      once: true,
      offset: 120,
    });
  }, []);
  const workesData = [
    {
      id: 1,
      Img: understanding,
      SubTitle: "01",
      Title: "Understanding your needs",
      Color: "bg-secondary-color",
      Text: "We start by listening attentively to our clients and understanding their goals, challenges, and aspirations. Like a skilled gardener who assesses soil conditions, we analyze the landscape to determine the best approach.",
    },
    {
      id: 2,
      SubTitle: "02",
      Img: planningstrategy,
      Title: "Planning and strategy",
      Color: "bg-blue",
      Text: "With a clear understanding of the terrain, we develop a strategic roadmap tailored to our client's target. Like plotting out a garden bed, we meticulously plan each step, ensuring our efforts yield the desired results.",
    },
    {
      id: 3,
      SubTitle: "03",
      Img: cultivating,
      Title: "Cultivating creativity",
      Color: "bg-blue-light",
      Text: "Armed with insights and strategy, we unleash our creativity to craft compelling solutions. Whether it's designing a website, developing an app, or launching a marketing campaign, we cultivate ideas that resonate with our client's audience.",
    },
    {
      id: 4,
      SubTitle: "04",
      Img: nurturinggrowth,
      Title: "Nurturing growth",
      Color: "bg-orange-light",
      Text: "Like watering and fertilizing plants, we nurture our thought process of the project throughout its lifecycle. We monitor progress, address challenges, and make adjustments as needed to ensure steady growth and optimal performance.",
    },
    {
      id: 5,
      SubTitle: "05",
      Img: harvesting,
      Title: "Harvesting results",
      Color: "bg-red",
      Text: "When the time comes, we reap the fruits of our labor. Whether it's increased website traffic, higher conversion rates, or enhanced brand visibility, we deliver tangible results that drive business growth.",
    },
    {
      id: 6,
      SubTitle: "06",
      Img: cultivatingrelationships,
      Title: "Cultivating relationships",
      Color: "bg-green",
      Text: "Just as a gardener tends to their plants, we cultivate long-term relationships with our clients. We provide ongoing support, offer strategic guidance, and adapt to their evolving needs to foster mutual success.",
    },
  ];
  return (
    <>
      <section className="crumina-module crumina-module-slider bg-blue-lighteen section-padding how-work-bg">
        <Container>
          <Row className="mb-5">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div
                className="sec-title"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                <img className="cloud" src={Cloud26} alt="cloud" />
                <h2>
                  <span>How we work</span>
                </h2>
              </div>
            </div>
          </Row>

          <Row>
            <div className="col-lg-12 col-sm-12 col-md-12">
              <Swiper
                className="swiper-container swiper-swiper-unique-id-0 swiper-container-horizontal pagination-bottom"
                id="swiper-unique-id-0"
                spaceBetween={30}
                loop={true}
                navigation={{
                  nextEl: ".btn-next",
                  prevEl: ".btn-prev",
                }}
                modules={[Navigation]}
                slidesPerView={1} // Default for larger screens
                slidesPerGroup={3} // Default for larger screens
                speed={1000} // Set transition speed to 1000ms (1 second)
                breakpoints={{
                  640: {
                    slidesPerView: 1, // Show only 1 slide at a time on small screens
                    slidesPerGroup: 1, // Move 1 slide at a time on small screens
                  },
                  768: {
                    slidesPerView: 2, // Show 2 slides at a time on medium screens
                    slidesPerGroup: 2, // Move 2 slides at a time on medium screens
                  },
                  1024: {
                    slidesPerView: 3, // Show 3 slides at a time on larger screens
                    slidesPerGroup: 3, // Move 3 slides at a time on larger screens
                  },
                }}
              >
                {workesData.map((data) => (
                  <SwiperSlide key={data.id}>
                    <div className="crumina-module crumina-info-box info-box--time-line">
                      <div className={`info-box-image ${data.Color}`}>
                        <img
                          className="utouch-icon"
                          src={data.Img}
                          alt="flag"
                        />
                        <div className="utouch-icon utouch-icon-dot-arrow time-line-arrow d-none">
                          <img
                            src={dotarrow}
                            className="img-fluid"
                            alt="dot arrow"
                          />
                        </div>
                      </div>

                      <div className="info-box-content">
                        <h5 className="mb-0">
                          {data.SubTitle}
                          <br />
                        </h5>
                        <p className="h6 info-box-title mt-0">{data.Title}</p>
                        <p className="info-box-text">{data.Text}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}

                <div className="btn-slider-wrap navigation-center-bottom">
                  <div className="btn-prev">
                    <img
                      src={arrowlg}
                      className="img-fluid arrow-lg utouch-icon icon-hover utouch-icon-arrow-right-1"
                      alt="Arrow"
                      style={{ transform: "rotate(180deg)" }}
                    />
                    <img
                      src={arrowsm}
                      className="img-fluid arrow-sm utouch-icon utouch-icon-arrow-right1"
                      alt="Arrow"
                      style={{ transform: "rotate(180deg)" }}
                    />
                  </div>

                  <div className="btn-next">
                    <img
                      src={arrowlg}
                      className="img-fluid arrow-lg utouch-icon icon-hover utouch-icon-arrow-right-1"
                      alt="Arrow"
                    />
                    <img
                      src={arrowsm}
                      className="img-fluid arrow-sm utouch-icon utouch-icon-arrow-right1"
                      alt="Arrow"
                    />
                  </div>
                </div>
              </Swiper>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
};
