import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";

import Cloud26 from "../../../Img/clouds26.png";
import AOS from "aos";

import cms from "../../../Img/service-icons/web-dev/cms.svg";
import ecommerce from "../../../Img/service-icons/web-dev/ecommerce.svg";
import portal from "../../../Img/service-icons/web-dev/portal.svg";
import crm from "../../../Img/service-icons/web-dev/crm.svg";
export default function WebAppServices() {
  useEffect(() => {
    AOS.init({
      once: true,
      offset: 120,
    });
  }, []);
  const webAppServicesData = [
    {
      id: 1,
      Img: cms,
      Title: "CMS development",
      Text: "We are proficient in working with popular CMS platforms like WordPress and Joomla. Whether you need a simple blog or a complex multi-page website, we can leverage CMS technology to streamline content management and site maintenance.",
    },
    {
      id: 2,
      Img: portal,
      Title: "Online portal",
      Text: "An online portal is a one-stop digital platform that provides users with easy access to a wide array of information, services, or tools. Whether for business, education, or communication, an online portal simplifies interactions by centralizing these services in one place.",
    },
    {
      id: 3,
      Img: crm,
      Title: "CRM & ERP application",
      Text: "When integrated, CRM & ERP systems provide a holistic view of your business by uniting sales, customer data, and back-office operations. This integration helps businesses improve decision-making, enhance productivity, and increase profitability.",
    },
    {
      id: 4,
      Img: ecommerce,
      Title: "E-Commerce website",
      Text: "We specialize in developing robust e-commerce solutions that enable you to sell products or services online. Our team is experienced in integrating secure payment gateways, managing product catalogs, and optimizing the user experience for maximum conversions.",
    },
  ];

  return (
    <>
      <section className="why-graphic-design bg-blue-lighteen section-padding-skew section-bg-skew service-bg-1">
        <Container>
          <Row className="mb-5">
            <div className="col-lg-12">
              <div
                className="sec-title"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                <img className="cloud" src={Cloud26} alt="cloud" />
                <h2>
                  <span>Web application services</span>
                </h2>
              </div>
            </div>
          </Row>
          <Row className="g-3 teammembers-wrap">
            {webAppServicesData.map((webAppServices) => (
              <div
                className="col-lg-6 col-md-6 col-sm-12 col-xs-12"
                key={webAppServices.id}
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                <div className="crumina-module crumina-teammembers-item icon_box_left">
                  <div className="teammembers-thumb">
                    <img src={webAppServices.Img} alt={webAppServices.Title} />
                  </div>

                  <div className="teammember-content">
                    <div className="teammembers-item-prof">
                      {webAppServices.Title}
                    </div>

                    <p>{webAppServices.Text}</p>
                  </div>
                </div>
              </div>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
}
