import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";

import Cloud8 from "../../../Img/clouds8.png";
import AOS from "aos";

import experiencedteam from "../../../Img/service-icons/graphic-design/experienced-team.svg";
import centricapproach from "../../../Img/service-icons/graphic-design/centric-approach.svg";
import innovative from "../../../Img/service-icons/graphic-design/innovative-solutions.svg";
import timelydelivery from "../../../Img/service-icons/graphic-design/timely-delivery.svg";

const GraphicDesignWhyChoose = () => {
  useEffect(() => {
    AOS.init({
      once: true,
      offset: 120,
    });
  }, []);
  const Whychoosedatalist = [
    {
      id: 1,
      Img: experiencedteam,
      Title: "Experienced team",
      Text: "Our talented designers bring a wealth of experience and creativity to every project",
    },
    {
      id: 2,
      Img: centricapproach,
      Title: "Client-Centric approach",
      Text: "We prioritise your needs and work collaboratively to achieve your vision.",
    },
    {
      id: 3,
      Img: innovative,
      Title: "Innovative solutions",
      Text: "We stay updated on design trends and technologies, ensuring your project stands out in a competitive market.",
    },
    {
      id: 4,
      Img: timelydelivery,
      Title: "Timely delivery",
      Text: "We respect deadlines and strive to deliver your projects on time, without compromising quality.",
    },
  ];
  return (
    <>
      <section className="section-padding">
        <Container>
          <Row className="mb-5">
            <div className="col-lg-12">
              <div
                className="sec-title"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                <img className="cloud" src={Cloud8} alt="cloud" />
                <h2>
                  <span>Why choose us?</span>
                </h2>
              </div>
            </div>
          </Row>
          <Row className="g-5">
            {Whychoosedatalist.map((Whychoosedata) => (
              <div
                className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                key={Whychoosedata.id}
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                <div className="crumina-module crumina-info-box info-box--standard-hover text-start">
                  <div className="info-box-image">
                    <img
                      className="utouch-icon img-fluid"
                      alt="Services"
                      src={Whychoosedata.Img}
                    />
                  </div>
                  <div className="info-box-content">
                    <h6 className="info-box-title">{Whychoosedata.Title}</h6>
                    <p className="info-box-text">{Whychoosedata.Text}</p>
                  </div>
                </div>
              </div>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default GraphicDesignWhyChoose;
