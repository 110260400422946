import React, { useState, useEffect } from "react";
import "./Portfolio.css";
import Modal from "react-bootstrap/Modal";
import WebConfig from "../../WebConfig";
import Expand from "../../Img/icons/Expand.svg";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Row } from "react-bootstrap";

export default function Portfolio() {
  const ApiUrl = WebConfig.ApiUrl;
  const [filter, setFilter] = useState("*");
  const [portfolioItems, setPortfolioItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [displayedItemsCount, setDisplayedItemsCount] = useState(15); // Initialize to show 20 items

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(`${ApiUrl}/get_portfolio_data_1.php`);
      const data = await res.json();
      if (data.status === "1" && data.result === "Success") {
        setPortfolioItems(data.portfolio_data_list);
        // Initially filter all items
        setFilteredItems(combineAllItems(data.portfolio_data_list));
      }
    };
    fetchData();
  }, [ApiUrl]);

  // Function to combine all category items for the "All" filter
  const combineAllItems = (categories) => {
    return categories.reduce((acc, category) => {
      return [...acc, ...category.port_items];
    }, []);
  };

  const handleFilter = (value) => {
    setFilter(value);
    setDisplayedItemsCount(15); // Reset displayed item count when filter changes

    if (value === "*") {
      // For "All" category, combine all items
      setFilteredItems(combineAllItems(portfolioItems));
    } else {
      // Filter specific category items
      const filtered = portfolioItems.filter(
        (category) => category.portfolio_cat_slug === value
      );
      if (filtered.length > 0) {
        setFilteredItems(filtered[0].port_items);
      } else {
        setFilteredItems([]);
      }
    }
  };

  const handleShowModal = (item) => {
    setSelectedItem(item);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setSelectedItem(null);
  };

  const handleLoadMore = () => {
    setDisplayedItemsCount((prevCount) => prevCount + 15); // Increase by 12 on load more
  };

  return (
    <>
      <section className="align-center section-padding portfolio_list">
        <div className="container">
          <Row className="mb-5">
            <div className="col-lg-12">
              <div className="sec-title">
                <h3>
                  <span>
                    From concept to creation here's a glimpse <br />
                    of my creative journey.
                  </span>
                </h3>
              </div>
            </div>
          </Row>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <ul className="cat-list-bg-style sorting-menu mb-5">
                <li
                  className={`cat-list__item ${filter === "*" ? "active" : ""}`}
                  onClick={() => handleFilter("*")}
                >
                  <Button type="button">All</Button>
                </li>
                {portfolioItems.map((category) => (
                  <li
                    key={category.portfolio_cat_id}
                    className={`cat-list__item ${
                      filter === category.portfolio_cat_slug ? "active" : ""
                    }`}
                    onClick={() => handleFilter(category.portfolio_cat_slug)}
                  >
                    <Button type="button">{category.portfolio_cat_name}</Button>
                  </li>
                ))}
              </ul>
              <div className="row sorting-container" id="clients-grid">
                {filteredItems.length > 0 ? (
                  filteredItems
                    .slice(0, displayedItemsCount) // Limit items based on displayedItemsCount
                    .map((item) => {
                      // Get the category of the item by finding the parent category
                      const category = portfolioItems.find((cat) =>
                        cat.port_items.some(
                          (portItem) =>
                            portItem.portfolio_id === item.portfolio_id
                        )
                      );

                      // Check if the category is "website"
                      const isWebsiteCategory =
                        category?.portfolio_cat_slug === "website";

                      return (
                        <div
                          key={item.portfolio_id}
                          className={`col-lg-4 col-md-4 col-sm-6 col-xs-6 p-1 sorting-item smm slideup slide-up ${
                            filter !== "*" ? filter : ""
                          }`}
                        >
                          <div className="screenshots-item mb-1">
                            <img
                              src={item.portfolio_fimage}
                              alt={item.portfolio_name}
                              className="img-fluid portfolio-thump"
                            />
                            <div className="overlay-standard overlay--blue-dark"></div>
                            <Button
                              className="expand js-zoom-image"
                              onClick={() => handleShowModal(item)}
                            >
                              <img
                                src={Expand}
                                alt="Expand"
                                className="utouch-icon"
                              />
                              Expand
                            </Button>
                            {/* Show the visit link only for the 'website' category */}
                            {isWebsiteCategory && item.website_link && (
                              <div className="visit_link">
                                {/* <Link to={item.website_link} target="_blank">
                                  {item.website_link}
                                </Link> */}

                                <Link
                                  to={
                                    item.website_link ? item.website_link : "#"
                                  }
                                  target={
                                    item.website_link ? "_blank" : "_self"
                                  }
                                >
                                  {item.website_link}
                                </Link>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })
                ) : (
                  <div className="col-12 text-center">
                    <h6>No items found.</h6>
                  </div>
                )}
              </div>

              {filteredItems.length > displayedItemsCount && (
                <Button
                  className="btn btn--large btn-border btn--with-shadow btn-more btn--grey-light"
                  id="load-more-button"
                  onClick={handleLoadMore}
                >
                  Load More
                </Button>
              )}
            </div>
          </div>
        </div>
      </section>

      <Modal show={show} onHide={handleClose} className="portfolio-model">
        <Modal.Header
          closeButton
          style={{ borderBottom: "none" }}
          data-bs-theme="dark"
        ></Modal.Header>

        <Modal.Body className="mx-auto">
          {selectedItem && selectedItem.portfolio_image && (
            <img
              src={selectedItem.portfolio_image}
              alt="Expand"
              className="expand js-zoom-image img-fluid"
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
