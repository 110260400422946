import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import casepic1 from "../../Img/case-study/ariviyal-case-listing.jpg";
import { Link } from "react-router-dom";
import Cloud8 from "../../Img/clouds8.png";
import arrowsm from "../../Img/arrow/arrow-sm-1.png";
import arrowlg from "../../Img/arrow/arrow-lg-1.png";
import AOS from "aos";
export default function HomeCaseStudy2() {
  const casestudylists = [
    {
      id: 1,
      img: casepic1,
      title: "Ariviyal Publishing",
      text: "Website Design & Development",
      link: "#",
    },
    {
      id: 2,
      img: casepic1,
      title: "Ariviyal Publishing",
      text: "Website Design & Development",
      link: "#",
    },
    {
      id: 3,
      img: casepic1,
      title: "Ariviyal Publishing",
      text: "Website Design & Development",
      link: "#",
    },
  ];

  useEffect(() => {
    AOS.init({
      once: true,
      offset: 120,
    });
  }, []);
  return (
    <>
      <section className="section-padding d-none">
        <Container>
          <Row className="mb-5">
            <div className="col-lg-12">
              <div className="sec-title">
                <img className="cloud" src={Cloud8} alt="cloud" />
                <h2>
                  <span>Case study</span>
                </h2>
              </div>
            </div>
          </Row>
          <Row className="gy-5">
            {casestudylists.map((casestudylist) => (
              <div
                className="col-lg-4 col-md-6 col-sm-12 col-xs-12"
                key={casestudylist.id}
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                <div className="casestudy-card">
                  <img
                    src={casestudylist.img}
                    className="img-fluid casestudy-card-img"
                    alt="pic"
                  />
                  <div className="casestudy-card-footer">
                    <div className="case-card-name">
                      <h5>{casestudylist.title}</h5>
                      <p>{casestudylist.text}</p>
                    </div>
                    <div className="case-card-link">
                      <Link
                        to={casestudylist.link}
                        className="more-arrow btn btn--yellow hvr-bg-blue hvr-white"
                      >
                        View
                        <span className="btn-next">
                          <img
                            src={arrowlg}
                            className="img-fluid arrow-lg utouch-icon icon-hover utouch-icon-arrow-right-1"
                            alt="Arrow"
                          />
                          <img
                            src={arrowsm}
                            className="img-fluid arrow-sm utouch-icon utouch-icon-arrow-right1"
                            alt="Arrow"
                          />
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Row>
          <Row>
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <Link
                to="#"
                className="btn btn--yellow hvr-bg-blue hvr-white mt-5"
              >
                Explore Our Work
              </Link>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
}
