import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import Cloud8 from "../../../Img/clouds8.png";
import AOS from "aos";

export default function DomainsHostingProfileContent() {
  useEffect(() => {
    AOS.init({
      once: true,
      offset: 120,
    });
  }, []);
  return (
    <>
      <section className="content-block">
        <Container>
          <Row>
            <div className="col-lg-12">
              <div
                className="sec-title"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                <img className="cloud" src={Cloud8} alt="cloud" />
                <h2>
                  <span>
                    Unlock your online presence with our domain and hosting
                    services
                  </span>
                </h2>
              </div>
            </div>
          </Row>
          <Row>
            <div className="col-lg-12">
              <div
                className="heading-text"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                In today’s digital landscape, having a robust online presence is
                essential for any business. At Tulips Media, we offer
                comprehensive domain registration and hosting services designed
                to empower your brand and enhance your web development
                experience. Let us help you establish a strong foundation for
                your online journey!
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
}
