import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";

import Cloud8 from "../../../Img/clouds8.png";
import AOS from "aos";

import webdevelopment from "../../../Img/service-icons/web-design/web-development.svg";
import ecommercedev from "../../../Img/service-icons/web-design/e-commerce-dev.svg";
import cms from "../../../Img/service-icons/web-design/cms.svg";
import databsemanage from "../../../Img/service-icons/web-design/databse-manage.svg";
import uiux from "../../../Img/service-icons/web-design/ui-ux.svg";
import seo from "../../../Img/service-icons/web-design/seo.svg";
import security from "../../../Img/service-icons/web-design/security.svg";
import analytics from "../../../Img/service-icons/web-design/analytics.svg";

export default function WebDesignDev() {
  useEffect(() => {
    AOS.init({
      once: true,
      offset: 120,
    });
  }, []);
  const webDesignDevData = [
    {
      id: 1,
      Img: webdevelopment,
      Title: "Web development",
      Text: "We have expertise in developing websites using a variety of technologies, including HTML, CSS, JavaScript, and popular frameworks like React. Our team is proficient in building responsive, scalable, and user-friendly websites tailored to your specific needs.",
    },
    {
      id: 2,
      Img: ecommercedev,
      Title: "E-commerce development",
      Text: "We specialize in developing robust e-commerce solutions that enable you to sell products or services online. Our team is experienced in integrating secure payment gateways, managing product catalogs, and optimizing the user experience for maximum conversions.",
    },
    {
      id: 3,
      Img: cms,
      Title: "Content management systems(CMS)",
      Text: "We are proficient in working with popular CMS platforms like WordPress,and Joomla. Whether you need a simple blog or a complex multi-page website, we can leverage CMS technology to streamline content management and site maintenance.",
    },
    {
      id: 4,
      Img: databsemanage,
      Title: "Database management",
      Text: "Our team has experience in designing, implementing, and managing databases using technologie such as MySQL. We ensure efficient data storage, retrieval, and manipulation to support your website's functionality and performance.",
    },
    {
      id: 5,
      Img: uiux,
      Title: "UI/UX design",
      Text: "We have a dedicated team of UI/UX designers who specialize in creating intuitive and visually appealing user interfaces. We focus on understanding user behavior, designing wireframes and prototypes, and optimizing the user experience to enhance engagement and retention.",
    },
    {
      id: 6,
      Img: seo,
      Title: "Search engine optimization (SEO)",
      Text: "We understand the importance of optimizing your website for search engines to improve visibility and drive organic traffic. Our team employs proven SEO techniques, such as keyword research, on-page optimization, and link building, to boost your site's rankings and performance.",
    },
    {
      id: 7,
      Img: security,
      Title: "Security and performance optimization",
      Text: "We prioritize the security and performance of your website by implementing best practices and industry standards. We conduct security audits, implement SSL encryption, optimize page load times, and utilize caching techniques to ensure your site is both secure and fast.",
    },
    {
      id: 8,
      Img: analytics,
      Title: "Analytics and reporting",
      Text: "We integrate analytics tools like Google Analytics or Adobe Analytics to track website performance, user behavior, and conversion metrics. We provide comprehensive reporting and analysis to help you make informed decisions and optimize your digital strategy.",
    },
  ];

  return (
    <>
      <section className="section-padding">
        <Container>
          <Row className="mb-5">
            <div className="col-lg-12">
              <div
                className="sec-title"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                <img className="cloud img-fluid" src={Cloud8} alt="cloud" />
                <h2>
                  <span>
                    Certainly! here are some technical skills that Tulips Media
                    possesses
                  </span>
                </h2>
              </div>
            </div>
          </Row>
          <Row className="g-5">
            {webDesignDevData.map((webdata, index) => (
              <div
                className="col-lg-6 col-md-6 col-sm-12 col-xs-12"
                key={webdata.id}
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                <div className="crumina-module crumina-info-box info-box--standard-hover">
                  <div className="info-box-image">
                    <img
                      className="utouch-icon img-fluid"
                      alt="Services"
                      src={webdata.Img}
                    />
                  </div>
                  <div className="info-box-content">
                    <h5 className="info-box-title">{webdata.Title}</h5>
                    <p className="info-box-text">{webdata.Text}</p>
                  </div>
                </div>
              </div>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
}
