import React from "react";
import "../Solution.css";
import DesignSolutionBanner from "./DesignSolutionBanner";
import DesignSolutionContent from "./DesignSolutionContent";
import DesignSolutionList from "./DesignSolutionList";
import { CTASolution } from "../DigitalSolution/CTASolution";

export default function DesignSolutions() {
  return (
    <>
      <DesignSolutionBanner />
      <DesignSolutionContent />
      <DesignSolutionList />
      <CTASolution />
    </>
  );
}
