import React from "react";
import "./Portfolio.css";
import PortfolioList from "./PortfolioList";
import PortfolioBanner from "./PortfolioBanner";
import { Helmet } from "react-helmet-async";

export default function Portfolio() {
  return (
    <>
      <Helmet>
        <title>Portfolio | Tulips Media</title>
        <meta
          name="description"
          content="creative and technological solution agency"
        />
      </Helmet>
      <PortfolioBanner />
      <PortfolioList />
    </>
  );
}
