import React from "react";
import { Container, Row } from "react-bootstrap";
import casepic1 from "../../Img/case-study/ariviyal-case-listing.jpg";
import { Link } from "react-router-dom";

import arrowsm from "../../Img/arrow/arrow-sm-1.png";
import arrowlg from "../../Img/arrow/arrow-lg-1.png";

export default function CasestudyList() {
  const casestudylists = [
    {
      id: 1,
      img: casepic1,
      title: "Ariviyal Publishing",
      text: "Website Design & Development",
      link: "#",
    },
    {
      id: 2,
      img: casepic1,
      title: "Ariviyal Publishing",
      text: "Website Design & Development",
      link: "#",
    },
    {
      id: 3,
      img: casepic1,
      title: "Ariviyal Publishing",
      text: "Website Design & Development",
      link: "#",
    },
    {
      id: 4,
      img: casepic1,
      title: "Ariviyal Publishing",
      text: "Website Design & Development",
      link: "#",
    },
    {
      id: 5,
      img: casepic1,
      title: "Ariviyal Publishing",
      text: "Website Design & Development",
      link: "#",
    },
    {
      id: 6,
      img: casepic1,
      title: "Ariviyal Publishing",
      text: "Website Design & Development",
      link: "#",
    },
    {
      id: 7,
      img: casepic1,
      title: "Ariviyal Publishing",
      text: "Website Design & Development",
      link: "#",
    },
    {
      id: 8,
      img: casepic1,
      title: "Ariviyal Publishing",
      text: "Website Design & Development",
      link: "#",
    },
    {
      id: 9,
      img: casepic1,
      title: "Ariviyal Publishing",
      text: "Website Design & Development",
      link: "#",
    },
    {
      id: 10,
      img: casepic1,
      title: "Ariviyal Publishing",
      text: "Website Design & Development",
      link: "#",
    },
  ];
  return (
    <>
      <section className="pt100 pb100">
        <Container>
          <Row className="gy-3">
            {casestudylists.map((casestudylist) => (
              <div
                className="col-lg-6 col-md-6 col-sm-12 col-xs-12"
                key={casestudylist.id}
              >
                <div className="casestudy-card">
                  <img
                    src={casestudylist.img}
                    className="img-fluid casestudy-card-img"
                    alt="pic"
                  />
                  <div className="casestudy-card-footer">
                    <div className="case-card-name">
                      <h5>{casestudylist.title}</h5>
                      <p>{casestudylist.text}</p>
                    </div>
                    <div className="case-card-link">
                      <Link
                        to={casestudylist.link}
                        className="more-arrow btn btn--yellow hvr-bg-blue hvr-white"
                      >
                        View
                        <span className="btn-next">
                          <img
                            src={arrowlg}
                            className="img-fluid arrow-lg utouch-icon icon-hover utouch-icon-arrow-right-1"
                            alt="Arrow"
                          />
                          <img
                            src={arrowsm}
                            className="img-fluid arrow-sm utouch-icon utouch-icon-arrow-right1"
                            alt="Arrow"
                          />
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
}
