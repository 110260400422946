import React from "react";
import { Container, Row } from "react-bootstrap";

import img from "../../../Img/demo-screen-1.png";

export default function SMTravelsFeatures() {
  return (
    <>
      <section className="bg-blue-lighteen section-padding-skew section-bg-skew service-bg-1">
        <Container>
          <Row>
            {/* <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 negative-margin-bottom80 d-none">
              <div className="crumina-module crumina-double-image">
                <img
                  src="https://html.crumina.net/html-utouch/img/image6.png"
                  alt="img"
                  className="img-fluid"
                />
                <img
                  src="https://html.crumina.net/html-utouch/img/image7.png"
                  alt="img"
                  className="img-fluid"
                />
              </div>
            </div> */}
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <img src={img} className="img-fluid" alt="img" />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 pb80">
              <div className="crumina-module crumina-heading">
                <h3 className="heading-title">How It Works</h3>
                <div className="heading-text">
                  Benzear Equipments focuses on designing, developing and
                  manufacturing of engineering equipment such as Special Purpose
                  Machinery, In-Site Maintenance machines and Automotive garage
                  equipments. They serve for leading brands; Yamaha, Honda, TVS
                  etc. They required an ERP system that supports the areas of
                  trading, purchase, marketing, human resources, production,
                  sales, and service. Tulips Media team of professional studied
                  the entire process of the company and provided with a user-
                  friendly interface that helps in easy management.
                </div>

                <ul className="list list--standard">
                  <li>
                    <i className="bi bi-check pe-2"></i>ISMS integration
                  </li>
                  <li>
                    <i className="bi bi-check pe-2"></i>ITour package creation
                  </li>
                  <li>
                    <i className="bi bi-check pe-2"></i>Reminders/Notifications
                    for payments, follow-up, maintenance.
                  </li>
                  <li>
                    <i className="bi bi-check pe-2"></i>Drill-down reports such
                    as previous customer history on the report itself
                  </li>
                </ul>
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
}
