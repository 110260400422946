import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import AOS from "aos";
import Cloud8 from "../../../Img/clouds8.png";

export default function SMTravelsContent() {
  useEffect(() => {
    AOS.init({
      once: true,
      offset: 120,
    });
  }, []);
  return (
    <>
      <section className="content-block">
        <Container>
          <Row>
            <div className="col-lg-12">
              <div className="sec-title">
                <img className="cloud" src={Cloud8} alt="cloud" />
                <h2>
                  <span>Complete ERP for Manufacturing Company</span>
                </h2>
              </div>
            </div>
            <div className="col-lg-12">
              <div
                className="heading-text"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="50"
              >
                A travel agency works in multiple ways such as rentals,
                contracts, tour packages, etc. The most challenging tasks for SM
                Travels were managing the customers, drivers, vehicle
                maintenance, and inquiries. Tulips Media gave user-friendly
                solutions and made it easy to manage tasks with a web-based
                application system.
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
}
