import React from "react";
import { Container, Row } from "react-bootstrap";

import underlinewhite from "../../Img/title-underline-white.png";
import { Link } from "react-router-dom";

export const CasesutyCta = () => {
  return (
    <>
      <section className="casestudy-cta-bg background-cover align-center">
        <Container>
          <Row>
            <div className="col-lg-9 col-lg-offset-2 col-md-9 col-md-offset-2 col-sm-12 col-sm-offset-0 col-xs-12">
              <div className="crumina-module crumina-heading mb-0">
                <h4 className="heading-title text-white">Contact with us</h4>
                <h2 className="text-white">Watch your digital dreams bloom</h2>
                <img
                  src={underlinewhite}
                  className="img-fluid"
                  alt="underline"
                />
              </div>
            </div>
          </Row>
          <Row>
            <div className="col-lg-9 col-lg-offset-2 col-md-9 col-md-offset-2 col-sm-12 col-sm-offset-0 col-xs-12">
              <Link
                to="#"
                className="btn btn--blue hvr-bg-yellow hvr-white mt-5"
              >
                Request a Quote
              </Link>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
};
