import React from "react";
import { Container, Row } from "react-bootstrap";

import Cloud8 from "../../../Img/clouds8.png";

import php from "../../../Img/service-icons/mobile-app/php.svg";
import java from "../../../Img/service-icons/mobile-app/java.svg";
export default function WebAppDev() {
  const mobileAppDataList = [
    {
      id: 1,
      Img: php,
      Title: "PHP",
      Text: "PHP is primarily used for server-side scripting to handle the dynamic content of websites. Its main applications in web development include Generating dynamic page content PHP can create dynamic web pages and interact with databases to provide personalized content to users.",
    },
    {
      id: 2,
      Img: java,
      Title: "Java",
      Text: "A key advantage of Java application development lies in object-oriented programming concepts that are extensible, scalable, and adaptable. This language comes with a wide library of design patterns and other advanced technologies. Java also provides powerful interaction with native elements and speeds up development.",
    },
  ];

  return (
    <>
      <section className="section-padding">
        <Container>
          <Row className="mb-5">
            <div className="col-lg-12">
              <div className="sec-title">
                <img className="cloud" src={Cloud8} alt="cloud" />
                <h2>
                  <span>Mobile app development services</span>
                </h2>
              </div>
            </div>
          </Row>
          <Row className="g-5">
            {mobileAppDataList.map((mobileAppData) => (
              <div
                className="col-lg-6 col-md-6 col-sm-12 col-xs-12"
                key={mobileAppData.id}
              >
                <div className="crumina-module crumina-info-box info-box--standard-hover">
                  <div className="info-box-image">
                    <img
                      className="utouch-icon img-fluid"
                      alt="Services"
                      src={mobileAppData.Img}
                    />
                  </div>
                  <div className="info-box-content">
                    <h5 className="info-box-title">{mobileAppData.Title}</h5>
                    <p className="info-box-text">{mobileAppData.Text}</p>
                  </div>
                </div>
              </div>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
}
