import React from "react";
import { Usefullinks } from "./Usefullinks";
import { Services } from "./Services";
import { Copyrights } from "./Copyrights";
import { FooterAbout } from "./FooterAbout";
import { Contact } from "./Contact";
import "./Footer.css";

export default function Footer() {
  return (
    <footer className="footer" id="site-footer">
      <div className="header-lines-decoration">
        <span className="bg-secondary-color"></span>
        <span className="bg-blue"></span>
        <span className="bg-blue-light"></span>
        <span className="bg-orange-light"></span>
        <span className="bg-red"></span>
        <span className="bg-green"></span>
        <span className="bg-secondary-color"></span>
      </div>

      <div className="container">
        <div className="row gy-3">
          <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
            <FooterAbout />
          </div>

          <div className="col-lg-2 col-md-6 col-sm-6 col-xs-6">
            <Usefullinks />
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6">
            <Services />
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
            <Contact />
          </div>
        </div>
      </div>

      <Copyrights />
    </footer>
  );
}
