import React from "react";
import { Helmet } from "react-helmet-async";
import DigitalMarketingBanner from "./DigitalMarketingBanner";
import DigitalMarketingProfileContent from "./DigitalMarketingProfileContent";
import DigitalMarketingServices from "./DigitalMarketingServices";
import "../Services.css";
import { CallToAction } from "../../Template/CTA/CallToAction";

export default function DigitalMarketing() {
  return (
    <>
      <Helmet>
        <title>Digital Marketing</title>
        <meta
          name="description"
          content="creative and technological solution agency"
        />
      </Helmet>
      <DigitalMarketingBanner />
      <DigitalMarketingProfileContent />
      <DigitalMarketingServices />
      <CallToAction />
    </>
  );
}
