import React from "react";
import { Container, Row } from "react-bootstrap";

import BannerPic from "../../../Img/digital-solution-banner-pic.png";
import underline from "../../../Img/title-underline.png";

export default function SMTravelsBanner() {
  return (
    <>
      <section className="not-top-element section-bg-skew bg-blue-lighteen case-study-dtl-banner">
        <Container>
          <Row className="align-items-center">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="banner-desc">
                <div className="crumina-module crumina-heading">
                  <h6 className="heading-sup-title">Case Study</h6>
                  <h2 className="h1 heading-title mt-0">
                    Travel Management System
                  </h2>
                  <img
                    src={underline}
                    className="img-fluid underline"
                    alt="underline"
                  />
                </div>
                <img
                  src={BannerPic}
                  alt="Digital Solution Pic"
                  className="img-fluid banner-pic"
                />
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
}
