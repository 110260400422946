import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import Modal from "react-bootstrap/Modal";
import stc1 from "../../../Img/case-study/stc-1.jpg";
import stc2 from "../../../Img/case-study/stc-2.jpg";
import stc3 from "../../../Img/case-study/stc-3.jpg";
import stc4 from "../../../Img/case-study/stc-4.jpg";
import stc5 from "../../../Img/case-study/stc-5.jpg";

export default function STCourierScreenshots() {
  const [show, setShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleShowModal = (screenshots) => {
    setSelectedItem(screenshots);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setSelectedItem(null);
  };

  const screenshotsitems = [
    {
      id: 1,
      img: stc1,
    },
    {
      id: 2,
      img: stc2,
    },
    {
      id: 3,
      img: stc3,
    },
    {
      id: 4,
      img: stc4,
    },
    {
      id: 5,
      img: stc5,
    },
    // Add more testimonial objects as needed
  ];

  return (
    <>
      <section className="section-padding align-center mobile-app-slider">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-lg-offset-3 col-md-12 col-sm-12 col-xs-12 mb30">
              <div className="crumina-module crumina-heading">
                <h6 className="heading-sup-title">Screenshots</h6>
                <h2 className="heading-title">
                  Beautiful Design and Comfortable User Interface
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div className="crumina-module crumina-module-slider slider--full-width screenshots-slider-style1 navigation-center-both-sides casestudy-screenshots">
          <Swiper
            spaceBetween={60}
            navigation={{
              nextEl: ".btn-next",
              prevEl: ".btn-prev",
            }}
            // pagination={true}
            modules={[Navigation, Pagination]}
            loop={true}
            centeredSlides={true}
            className="swiper-container pagination-bottom swiper-swiper-unique-id-1 initialized swiper-container-horizontal"
            id="swiper-unique-id-1"
            // slidesPerView={5}
            breakpoints={{
              640: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
          >
            {screenshotsitems.map((screenshots) => (
              <SwiperSlide key={screenshots.id}>
                <div className="screenshot-item">
                  <div
                    className="js-zoom-image"
                    onClick={() => handleShowModal(screenshots)}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={screenshots.img}
                      alt="img"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </SwiperSlide>
            ))}
            {/* <div className="swiper-pagination"></div> */}
          </Swiper>
          <div className="btn-prev">
            <i className="utouch-icon fa fa-chevron-left"></i>
          </div>

          <div className="btn-next">
            <i className="utouch-icon fa fa-chevron-right"></i>
          </div>
        </div>
      </section>

      <Modal
        show={show}
        onHide={handleClose}
        className="case-study-model-screenshoot"
        centered
      >
        <Modal.Header
          style={{ borderBottom: "none" }}
          closeButton
          data-bs-theme="dark"
        ></Modal.Header>

        <Modal.Body style={{ maxWidth: "100%" }}>
          {selectedItem && selectedItem.img && (
            <div className="text-center w-100">
              <img
                src={selectedItem.img}
                alt="Expand"
                className="expand js-zoom-image img-fluid"
              />
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
