import React, { useState, useEffect } from "react";
import { Row, Container } from "react-bootstrap";
import WebConfig from "../../WebConfig";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper/modules";
import AOS from "aos";
import Cloud8 from "../../Img/clouds8.png";

export default function HomeTestimonial() {
  const ApiUrl = WebConfig.ApiUrl;
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    AOS.init({
      once: true,
      offset: 120,
    });
    const getProductsData = async () => {
      const res = await fetch(`${ApiUrl}/get_testimonial.php`);
      const getdata = await res.json();
      setTestimonials(getdata.testimonial_data_list);
    };
    getProductsData();
  }, [ApiUrl]);

  return (
    <section className="crumina-module crumina-module-slider navigation-center-both-sides section-padding testimonials bg-4 cloud-center">
      <Container>
        <Row className="mb-5">
          <div className="col-lg-12">
            <div className="sec-title">
              <img className="cloud" src={Cloud8} alt="cloud" />
              <h2>
                <span>What do our clients have to say</span>
              </h2>
            </div>
          </div>
        </Row>
        <Row>
          <div className="col-lg-6 col-lg-offset-3 col-md-6 col-md-offset-3 col-sm-12 col-sm-offset-0">
            <Swiper
              spaceBetween={30}
              navigation={{ nextEl: ".btn-next1", prevEl: ".btn-prev1" }}
              autoplay={{ delay: 10000, disableOnInteraction: false }}
              modules={[Autoplay, Navigation]}
              slidesPerView={1}
              speed={1000} // Set transition speed to 1000ms (1 second)
            >
              {testimonials.map((testimonial) => (
                <SwiperSlide key={testimonial.testimonial_id}>
                  <div className="crumina-module crumina-testimonial-item testimonial-item-author-top">
                    <div className="testimonial-img-author">
                      <img
                        src={testimonial.testimonial_img}
                        alt="avatar"
                        className="img-fluid"
                      />
                    </div>
                    <h6 className="testimonial-text">
                      {testimonial.testimonial_msg}
                    </h6>
                    <div className="author-info-wrap">
                      <div className="author-info">
                        <h6 className="h6 author-name">
                          {testimonial.testimonial_name}
                        </h6>
                        <div className="author-company d-none">
                          {testimonial.testimonial_author}
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </Row>

        {/* Prev next buttons */}
        <div className="btn-prev1">
          <i className="utouch-icon fa fa-chevron-left"></i>
        </div>
        <div className="btn-next1">
          <i className="utouch-icon fa fa-chevron-right"></i>
        </div>
      </Container>
    </section>
  );
}
