import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import Cloud26 from "../../../Img/clouds26.png";
import AOS from "aos";

import img1 from "../../../Img/service-icons/dedicated-hiring/recruitment-team.svg";
import img2 from "../../../Img/service-icons/dedicated-hiring/tailored-solutions.svg";
import img3 from "../../../Img/service-icons/dedicated-hiring/track-record.svg";
import img4 from "../../../Img/service-icons/dedicated-hiring/ongoing-support.svg";

const WhyuDedicatedHiring = () => {
  useEffect(() => {
    AOS.init({
      once: true,
      offset: 120,
    });
  }, []);
  const Whyusdatalist = [
    {
      id: 1,
      Img: img1,
      Title: "Expert Recruitment Team",
      Text: "Our experienced recruiters specialise in various industries, providing insights and expertise that enhance the quality of candidates we present.",
    },
    {
      id: 2,
      Img: img2,
      Title: "Tailored Solutions",
      Text: "We recognize that every business is unique. Our dedicated hiring services are customised to meet your specific needs and challenges.",
    },
    {
      id: 3,
      Img: img3,
      Title: "Proven Track Record",
      Text: "With a history of successful placements, we have built strong relationships with both clients and candidates, ensuring high satisfaction rates.",
    },
    {
      id: 4,
      Img: img4,
      Title: "Ongoing Support",
      Text: "Our partnership doesn’t end once the hire is made. We provide ongoing support to ensure both your organisation and the new employee thrive.",
    },
  ];
  return (
    <>
      <section className="section-padding bg-blue-lighteen">
        <Container>
          <Row className="mb-5">
            <div className="col-lg-12">
              <div
                className="sec-title"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                <img className="cloud" src={Cloud26} alt="cloud" />
                <h2>
                  <span>Why partner with Us?</span>
                </h2>
              </div>
            </div>
          </Row>
          <Row className="g-5">
            {Whyusdatalist.map((Whyusdata) => (
              <div
                className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                key={Whyusdata.id}
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                <div className="crumina-module crumina-info-box info-box--standard-hover text-start">
                  <div className="info-box-image">
                    <img
                      className="utouch-icon img-fluid"
                      alt="Services"
                      src={Whyusdata.Img}
                    />
                  </div>
                  <div className="info-box-content">
                    <h6 className="info-box-title">{Whyusdata.Title}</h6>
                    <p className="info-box-text">{Whyusdata.Text}</p>
                  </div>
                </div>
              </div>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default WhyuDedicatedHiring;
