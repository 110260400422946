import React, { useEffect } from "react";
import WhoWeAreMbl from "../../Img/who-we-are-mbl.jpg";
import welcomeicon1 from "../../Img/icons/who-icon-1.svg";
import welcomeicon2 from "../../Img/icons/who-icon-2.svg";
import welcomeicon3 from "../../Img/icons/who-icon-3.svg";
import welcomeicon4 from "../../Img/icons/who-icon-4.svg";
import welcomeicon5 from "../../Img/icons/who-icon-5.svg";
import WebConfig from "../../WebConfig";
import { Link } from "react-router-dom";
import AOS from "aos";

const HomeWelcome = () => {
  const webUrl = WebConfig.ConfigUrl;

  useEffect(() => {
    AOS.init({
      once: true,
      offset: 120,
    });
  }, []);
  return (
    <section className="welcome-bg">
      <div className="container">
        <div className="row ">
          <div className="crumina-module crumina-module-img-bottom welcome-bg-content pt100">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="crumina-module crumina-heading position-relative">
                <div className="heading-left clum-6">
                  <h2
                    className="heading-title"
                    data-aos="fade-up"
                    data-aos-duration="1200"
                  >
                    <span>Who we are</span>
                  </h2>
                </div>
                <div
                  className="heading-text"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="500"
                >
                  Tulips Media is a creative and technological solution agency
                  focused on ideating the imagination with the spirit of
                  building highly-interactive brand experiences. We work with a
                  passion for creativity and conception in every medium,
                  centered on the brand-user experience and responsible
                  enterprise results.
                </div>
                <div
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="600"
                >
                  <Link
                    to={`${webUrl}about-us`}
                    className="btn btn--yellow hvr-bg-blue hvr-white mt-5"
                  >
                    Know More
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="position-relative wel-icon-list">
            <div className="wel-icon wel-icon-1">
              <img src={welcomeicon1} alt="icon" className="img-fluid" />
            </div>
            <div className="wel-icon wel-icon-2">
              <img src={welcomeicon2} alt="icon" className="img-fluid" />
            </div>
            <div className="wel-icon wel-icon-3">
              <img src={welcomeicon3} alt="icon" className="img-fluid" />
            </div>
            <div className="wel-icon wel-icon-4">
              <img src={welcomeicon4} alt="icon" className="img-fluid" />
            </div>
            <div className="wel-icon wel-icon-5">
              <img src={welcomeicon5} alt="icon" className="img-fluid" />
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 px-0">
            <div className="who-we-are-mbl">
              <img src={WhoWeAreMbl} className="img-fluid" alt="Who We Are" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeWelcome;
