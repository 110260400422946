import React from "react";
import { Helmet } from "react-helmet-async";
import DomainsHostingBanner from "./DomainsHostingBanner";
import DomainsHostingProfileContent from "./DomainsHostingProfileContent";

import "../Services.css";
import { CallToAction } from "../../Template/CTA/CallToAction";
import WhyChooseDomainsHosting from "./WhyChooseDomainsHosting";
import HostingSolutions from "./HostingSolutions";
import DomainsServices from "./DomainsServices";

export default function DomainsHosting() {
  return (
    <>
      <Helmet>
        <title>Domains & Hosting</title>
        <meta
          name="description"
          content="creative and technological solution agency"
        />
      </Helmet>
      <DomainsHostingBanner />
      <DomainsHostingProfileContent />
      <WhyChooseDomainsHosting />
      <DomainsServices />
      <HostingSolutions />
      <CallToAction />
    </>
  );
}
