import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";

import clouds26 from "../../../Img/clouds26.png";
import AOS from "aos";

import img1 from "../../../Img/service-icons/dedicated-hiring/specialized-talent.svg";
import img2 from "../../../Img/service-icons/dedicated-hiring/cost-effective.svg";
import img3 from "../../../Img/service-icons/dedicated-hiring/scalability.svg";
import img4 from "../../../Img/service-icons/dedicated-hiring/experience-candidate.svg";
import img5 from "../../../Img/service-icons/dedicated-hiring/reduced-time.svg";
export default function WhyChooseDedicatedHiring() {
  useEffect(() => {
    AOS.init({
      once: true,
      offset: 120,
    });
  }, []);
  const whychoosedatalist = [
    {
      id: 1,
      Img: img1,
      Title: "Access to specialized talent",
      Desc: "Our dedicated hiring service focuses on sourcing candidates with the skills and experience that align with your specific requirements, ensuring you find the best fit for your team.",
    },
    {
      id: 2,
      Img: img2,
      Title: "Cost-Effective",
      Desc: "By outsourcing your recruitment process to us, you save time and resources. We handle everything from candidate sourcing to screening, allowing you to focus on your core business operations.",
    },
    {
      id: 3,
      Img: img3,
      Title: "Scalability",
      Desc: "Whether you need to fill a single position or build an entire team, our dedicated hiring service can scale according to your business needs. We adapt to your hiring volume and timelines.",
    },
    {
      id: 4,
      Img: img4,
      Title: "Enhanced candidate experience",
      Desc: "We prioritize candidate engagement throughout the hiring process, creating a positive experience that reflects well on your brand and attracts top talent.",
    },
    {
      id: 5,
      Img: img5,
      Title: "Reduced Time-to-Hire",
      Desc: "Our streamlined processes and expertise allow us to quickly identify and engage top candidates, reducing the time it takes to fill critical positions.",
    },
  ];
  return (
    <>
      <section className="why-graphic-design section-padding-skew section-bg-skew bg-blue-lighteen service-bg-1">
        <Container>
          <Row className="mb-5">
            <div className="col-lg-12">
              <div
                className="sec-title"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                <img className="cloud" src={clouds26} alt="cloud" />
                <h2>
                  <span>Why choose dedicated hiring?</span>
                </h2>
              </div>
            </div>
          </Row>
          <Row className="g-3 teammembers-wrap">
            {whychoosedatalist.map((whychoosedata) => (
              <div
                className="col-lg-6 col-md-6 col-sm-12 col-xs-12"
                key={whychoosedata.id}
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                <div className="crumina-module crumina-teammembers-item icon_box_left">
                  <div className="teammembers-thumb">
                    <img src={whychoosedata.Img} alt="team member" />
                  </div>

                  <div className="teammember-content">
                    <div className="teammembers-item-prof">
                      {whychoosedata.Title}
                    </div>

                    <p>{whychoosedata.Desc}</p>
                  </div>
                </div>
              </div>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
}
