import React from "react";
import { Link } from "react-router-dom";
import WebConfig from "../WebConfig";

export const Usefullinks = () => {
  const webUrl = WebConfig.ConfigUrl;
  return (
    <div className="widget w-list">
      <h5 className="widget-title">Quick links</h5>
      <ul className="list list--primary">
        <li>
          <Link to={`${webUrl}`}>Home</Link>
        </li>
        <li>
          <Link to={`${webUrl}about-us`}>About us</Link>
        </li>
        <li>
          <Link to={`${webUrl}portfolio`}>Portfolio</Link>
        </li>
        <li>
          <Link to={`${webUrl}reach-us`}>Reach us</Link>
        </li>
      </ul>
    </div>
  );
};
