import React from "react";
import "./Header.css";
import TopBar from "./TopBar";
import LogoBar from "./LogoBar";

export default function Header() {
  return (
    <>
      <header
        className="header header--menu-rounded headroom--not-bottom headroom--top animated"
        id="site-header"
      >
        <TopBar />
        <LogoBar />
      </header>
      <div className="header-spacer"></div>
    </>
  );
}
