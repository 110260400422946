import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import Cloud8 from "../../Img/clouds8.png";
import AOS from "aos";

export default function CasestudyContent() {
  useEffect(() => {
    AOS.init({
      once: true,
      offset: 120,
    });
  }, []);
  return (
    <>
      <section className="content-block">
        <Container>
          <Row>
            <div className="col-lg-12">
              <div
                className="sec-title"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                <img className="cloud" src={Cloud8} alt="cloud" />
                <h2>
                  <span>What we do</span>
                </h2>
              </div>
            </div>
            <div className="col-lg-12">
              <div
                className="heading-text"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                At Tulips Media, we believe in the power of storytelling through
                successful projects. Our case studies showcase the innovative
                solutions we've crafted for our clients, highlighting our
                approach, the challenges faced, and the impact of our work.
                Explore how we’ve helped businesses grow, engage audiences, and
                achieve their goals through strategic media and design.
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
}
