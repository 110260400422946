import React from "react";
import { Container, Row } from "react-bootstrap";

import img from "../../../Img/demo-screen-1.png";

export default function VairraDiamondsFeatures() {
  return (
    <>
      <section className="bg-blue-lighteen section-padding-skew section-bg-skew service-bg-1">
        <Container>
          <Row>
            {/* <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 negative-margin-bottom80 d-none">
              <div className="crumina-module crumina-double-image">
                <img
                  src="https://html.crumina.net/html-utouch/img/image6.png"
                  alt="img"
                  className="img-fluid"
                />
                <img
                  src="https://html.crumina.net/html-utouch/img/image7.png"
                  alt="img"
                  className="img-fluid"
                />
              </div>
            </div> */}
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <img src={img} className="img-fluid" alt="img" />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 pb80">
              <div className="crumina-module crumina-heading">
                <h3 className="heading-title">How It Works</h3>
                <div className="heading-text">
                  Vairra Diamonds is a leading traditional jeweller at Tiruppur,
                  Tamil Nadu. India. Existing web app were made with Oracle that
                  had various practical issues with no user friendly resources.
                  Tulips expertise supported with a new look that created
                  user-friendly experience for the employees in PHP platform.
                </div>
              </div>
              <ul className="list list--standard">
                <li>
                  <i className="bi bi-check pe-2"></i>Bar-code integration
                </li>
                <li>
                  <i className="bi bi-check pe-2"></i>Personalized Dashboard for
                  departments and MD
                </li>
                <li>
                  <i className="bi bi-check pe-2"></i>SMS integration
                </li>
                <li>
                  <i className="bi bi-check pe-2"></i>Customer appointment
                  management Estimation slip generation
                </li>
                <li>
                  <i className="bi bi-check pe-2"></i>Gold Chit Funds management
                  Locker Management
                </li>
              </ul>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
}
