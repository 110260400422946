import React from "react";
import { Container, Row } from "react-bootstrap";

import BannerPic from "../../Img/sub-banner/portfolio-pic.png";
import underline from "../../Img/title-underline.png";

export default function PortfolioBanner() {
  return (
    <>
      <section className="bg-blue-lighteen section-padding sub-banner">
        <Container className="h-100">
          <Row className="align-items-center h-100">
            <div className="col-lg-5 col-md-6 col-sm-12 col-xs-12">
              <div className="crumina-module-img-content">
                <div className="crumina-module crumina-heading">
                  <h6 className="heading-sup-title mb-0">Portfolio</h6>
                  <h2 className="heading-title">
                    Crafting seamless digital experiences with expert solutions
                  </h2>
                  <img
                    src={underline}
                    className="img-fluid"
                    alt="Case Study Banner"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-6 col-sm-12 col-xs-12">
              <img src={BannerPic} className="img-fluid" alt="Man" />
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
}
