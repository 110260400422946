import React from "react";
import { ContactForm } from "./ContactForm";
import { ContactAddress } from "./ContactAddress";
import { Container, Row } from "react-bootstrap";

export const ContactContainer = () => {
  return (
    <>
      <section className="pt100 pb100 contact-section section-padding">
        <Container>
          <Row>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 contact-section-form">
              <ContactForm />
            </div>

            <div className="col-lg-6 col-md-6 col-md-12 col-sm-12 contact-section-address">
              <ContactAddress />
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
};
