import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import CountUp from "react-countup";
import AOS from "aos";

export const HomeStatics = () => {
  useEffect(() => {
    AOS.init({
      once: true,
      offset: 120,
    });
  }, []);
  return (
    <>
      <section className="bg-secondary-color background-cover bg-10 text-center">
        <Container>
          <Row className="counters">
            <div
              className="col-lg-4 col-md-4 col-sm-12 col-xs-12"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="500"
            >
              <div className="crumina-module crumina-counter-item">
                <div className="counter-numbers counter c-yellow">
                  <CountUp end={750} />
                  <div className="units">+</div>
                </div>
                <h5 className="counter-title">No of Projects</h5>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-4 col-sm-12 col-xs-12"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="500"
            >
              <div className="crumina-module crumina-counter-item">
                <div className="counter-numbers counter c-yellow">
                  <CountUp end={500} />
                  <div className="units">+</div>
                </div>
                <h5 className="counter-title">Clients</h5>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-4 col-sm-12 col-xs-12"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="500"
            >
              <div className="crumina-module crumina-counter-item">
                <div className="counter-numbers counter c-yellow">
                  <CountUp end={18} />
                  <div className="units">+</div>
                </div>
                <h5 className="counter-title">Years Experience</h5>
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
};
