import React from "react";
import logo from "../Img/logo/tulips-logo-white.png";

export const FooterAbout = () => {
  return (
    <div className="widget w-info">
      <div className="site-logo">
        <a href="/">
          <img
            src={logo}
            alt="Tulips Media"
            width="50%"
            className="img-fluid"
          />
        </a>
      </div>

      <p>
        Tulips Media is a creative and technological solution agency focussed on
        ideating the imagination with the spirit of building highly interactive
        brand experiences.
        {/* we work with a passion for creativity and conception
        in very medium, centered on the brand-user experience and respond to
        enterprise results. */}
      </p>
    </div>
  );
};
