import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import Cloud26 from "../../../Img/clouds26.png";
import AOS from "aos";

import personalwebsites from "../../../Img/service-icons/web-design/personal-websites-blogs.svg";
import businesswebsites from "../../../Img/service-icons/web-design/business-websites.svg";
import ecommerce from "../../../Img/service-icons/web-design/e-commerce.svg";
import portfoliowebsites from "../../../Img/service-icons/web-design/portfolio-websites.svg";
import educationalwebsites from "../../../Img/service-icons/web-design/educational-websites.svg";
import newsandmedia from "../../../Img/service-icons/web-design/news-and-media.svg";
import communityforum from "../../../Img/service-icons/web-design/community-forum.svg";
import nonprofit from "../../../Img/service-icons/web-design/nonprofit.svg";
import government from "../../../Img/service-icons/web-design/government-websites.svg";
import socialnetworking from "../../../Img/service-icons/web-design/social-networking.svg";

export default function WebDesignServices() {
  useEffect(() => {
    AOS.init({
      once: true,
      offset: 120,
    });
  }, []);
  const webServicesData = [
    {
      id: 1,
      Img: personalwebsites,
      Title: "Personal websites/blogs",
      Text: "These are often created by individuals to share their thoughts, experiences, hobbies, or expertise with others. They can range from simple personal blogs to more complex portfolio sites showcasing creative work.",
    },
    {
      id: 2,
      Img: businesswebsites,
      Title: "Business websites",
      Text: "These are created for businesses to establish an online presence and provide information about their history, products, services, and contact details.",
    },
    {
      id: 3,
      Img: ecommerce,
      Title: "E-Commerce websites",
      Text: "These websites are specifically designed for selling products or services online. They often include features such as product listings, shopping carts, payment gateways, and order management systems.",
    },
    {
      id: 4,
      Img: portfoliowebsites,
      Title: "Portfolio websites",
      Text: "These are commonly used by artists, designers, photographers, and other creative professionals to showcase their work to potential clients or employers. They typically feature galleries or portfolios of past projects.",
    },
    {
      id: 5,
      Img: educationalwebsites,
      Title: "Educational websites",
      Text: "These websites are focused on providing educational content, courses, tutorials, or resources to learners. They can range from simple platforms offering free content to more structured online learning platforms with interactive features.",
    },
    {
      id: 6,
      Img: newsandmedia,
      Title: "News and media websites",
      Text: "These websites provide news, articles, videos, and other forms of media content to users. They can cover a wide range of topics, from general news to niche interests.",
    },
    {
      id: 7,
      Img: communityforum,
      Title: "Community/Forum websites",
      Text: "These websites facilitate online communities where users can interact with each other, share ideas, ask questions, and participate in discussions. They often include features such as forums, chat rooms, and user profiles.",
    },
    {
      id: 8,
      Img: nonprofit,
      Title: "Nonprofit websites",
      Text: "These are created by nonprofit organizations to raise awareness about their cause, attract donors and volunteers, and provide information about their initiatives and impact.",
    },
    {
      id: 9,
      Img: government,
      Title: "Government websites",
      Text: "These websites are created by government agencies to provide information and services to citizens, businesses, or other stakeholders. They can include resources such as forms, regulations, and contact details.",
    },
    {
      id: 10,
      Img: socialnetworking,
      Title: "Social networking websites",
      Text: "These platforms allow users to create profiles, connect with others, share content, and communicate with each other. Examples include Facebook, Twitter, LinkedIn, and Instagram.",
    },
  ];

  return (
    <>
      <section className="bg-blue-lighteen section-padding-skew section-bg-skew service-bg-1 why-graphic-design">
        <Container>
          <Row className="mb-5">
            <div className="col-lg-12">
              <div
                className="sec-title"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                <img className="cloud" src={Cloud26} alt="cloud" />
                <h2>
                  <span>Industries we serve</span>
                </h2>
              </div>
            </div>
          </Row>

          <Row className="g-3 teammembers-wrap">
            {webServicesData.map((webServices) => (
              <div
                className="col-lg-6 col-md-6 col-sm-6 col-xs-12"
                key={webServices.id}
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                <div className="crumina-module crumina-teammembers-item icon_box_left">
                  <div className="teammembers-thumb">
                    <img
                      src={webServices.Img}
                      alt={webServices.Title}
                      className="img-fluid"
                    />
                  </div>

                  <div className="teammember-content">
                    <div className="teammembers-item-prof">
                      {webServices.Title}
                    </div>

                    <p>{webServices.Text}</p>
                  </div>
                </div>
              </div>
            ))}
          </Row>
          <Row>
            <div
              className="col-lg-12"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="500"
            >
              <p>
                There are many other types of websites catering to specific
                niches, industries, or purposes. The type of website you need
                will depend on your goals, target audience, and the nature of
                your content or business. We are here for you to discuss,
                design, and develop your online presence.{" "}
              </p>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
}
