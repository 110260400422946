import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import AOS from "aos";
import Cloud8 from "../../../Img/clouds8.png";

export default function BenzearContent() {
  useEffect(() => {
    AOS.init({
      once: true,
      offset: 120,
    });
  }, []);
  return (
    <>
      <section className="content-block">
        <Container>
          <Row>
            <div className="col-lg-12">
              <div className="sec-title">
                <img className="cloud" src={Cloud8} alt="cloud" />
                <h2>
                  <span>Complete ERP for Manufacturing Company</span>
                </h2>
              </div>
            </div>
            <div className="col-lg-12">
              <div
                className="heading-text"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="50"
              >
                Benzear Equipments focuses on designing, developing and
                manufacturing of engineering equipment such as Special Purpose
                Machinery, In-Site Maintenance machines and Automotive garage
                equipments. They serve for leading brands; Yamaha, Honda, TVS
                etc. They required an ERP system that supports the areas of
                trading, purchase, marketing, human resources, production,
                sales, and service. Tulips Media team of professional studied
                the entire process of the company and provided with a user-
                friendly interface that helps in easy management.
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
}
