import React from "react";
import SMTravelsContent from "./SMTravelsContent";
import SMTravelsBanner from "./SMTravelsBanner";
import SMTravelsFeatures from "./SMTravelsFeatures";
import SMTravelsScreenshots from "./SMTravelsScreenshots";
import "../../Casestudy/Casestudy.css";

export default function SMTravels() {
  return (
    <>
      <SMTravelsBanner />
      <SMTravelsContent />
      <SMTravelsFeatures />
      <SMTravelsScreenshots />
    </>
  );
}
