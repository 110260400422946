import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import AOS from "aos";
import Cloud8 from "../../../Img/clouds8.png";

export default function VairraDiamondsContent() {
  useEffect(() => {
    AOS.init({
      once: true,
      offset: 120,
    });
  }, []);
  return (
    <>
      <section className="content-block">
        <Container>
          <Row>
            <div className="col-lg-12">
              <div className="sec-title">
                <img className="cloud" src={Cloud8} alt="cloud" />
                <h2>
                  <span>Vairra Diamonds-Jewellery Management Software</span>
                </h2>
              </div>
            </div>
            <div className="col-lg-12">
              <div
                className="heading-text"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="50"
              >
                Vairra Diamonds is a leading traditional jeweller at Tiruppur,
                Tamil Nadu. India. Existing web app were made with Oracle that
                had various practical issues with no user friendly resources.
                Tulips expertise supported with a new look that created
                user-friendly experience for the employees in PHP platform.
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
}
