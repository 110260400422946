import React from "react";
import { Container, Row } from "react-bootstrap";

import onlineoarketingicon1 from "../../../Img/icons/seo-icon.png";
import onlineoarketingicon2 from "../../../Img/icons/sem-icon.png";
import onlineoarketingicon3 from "../../../Img/icons/e-mail-marketing.png";
import onlineoarketingicon4 from "../../../Img/icons/smm-icon.png";

export default function DesignSolutionList() {
  const DesignSolutionListData = [
    {
      Img: onlineoarketingicon1,
      Title: "Logo & Stationery",
      Text: "At Tulips Media, we believe in crafting websites that are not just visually stunning but also strategically designed to drive results. Our team of creative experts merges cutting-edge design with intuitive user experiences to ensure your website captivates visitors and converts them into loyal customers. ",
    },
    {
      Img: onlineoarketingicon2,
      Title: "Brochure",
      Text: "At Tulips Media, we believe in crafting websites that are not just visually stunning but also strategically designed to drive results. Our team of creative experts merges cutting-edge design with intuitive user experiences to ensure your website captivates visitors and converts them into loyal customers. ",
    },
    {
      Img: onlineoarketingicon3,
      Title: "Flyer",
      Text: "At Tulips Media, we believe in crafting websites that are not just visually stunning but also strategically designed to drive results. Our team of creative experts merges cutting-edge design with intuitive user experiences to ensure your website captivates visitors and converts them into loyal customers. ",
    },
    {
      Img: onlineoarketingicon4,
      Title: "Catelogue",
      Text: "At Tulips Media, we believe in crafting websites that are not just visually stunning but also strategically designed to drive results. Our team of creative experts merges cutting-edge design with intuitive user experiences to ensure your website captivates visitors and converts them into loyal customers. ",
    },
    {
      Img: onlineoarketingicon4,
      Title: "Menu Card",
      Text: "At Tulips Media, we believe in crafting websites that are not just visually stunning but also strategically designed to drive results. Our team of creative experts merges cutting-edge design with intuitive user experiences to ensure your website captivates visitors and converts them into loyal customers. ",
    },
    {
      Img: onlineoarketingicon4,
      Title: "Invitation",
      Text: "At Tulips Media, we believe in crafting websites that are not just visually stunning but also strategically designed to drive results. Our team of creative experts merges cutting-edge design with intuitive user experiences to ensure your website captivates visitors and converts them into loyal customers. ",
    },
    {
      Img: onlineoarketingicon4,
      Title: "Packing",
      Text: "At Tulips Media, we believe in crafting websites that are not just visually stunning but also strategically designed to drive results. Our team of creative experts merges cutting-edge design with intuitive user experiences to ensure your website captivates visitors and converts them into loyal customers. ",
    },
    {
      Img: onlineoarketingicon4,
      Title: "Annual Report",
      Text: "At Tulips Media, we believe in crafting websites that are not just visually stunning but also strategically designed to drive results. Our team of creative experts merges cutting-edge design with intuitive user experiences to ensure your website captivates visitors and converts them into loyal customers. ",
    },
    {
      Img: onlineoarketingicon4,
      Title: "Dngler",
      Text: "At Tulips Media, we believe in crafting websites that are not just visually stunning but also strategically designed to drive results. Our team of creative experts merges cutting-edge design with intuitive user experiences to ensure your website captivates visitors and converts them into loyal customers. ",
    },
    {
      Img: onlineoarketingicon4,
      Title: "News Letter",
      Text: "At Tulips Media, we believe in crafting websites that are not just visually stunning but also strategically designed to drive results. Our team of creative experts merges cutting-edge design with intuitive user experiences to ensure your website captivates visitors and converts them into loyal customers. ",
    },
    {
      Img: onlineoarketingicon4,
      Title: "Hoarding",
      Text: "At Tulips Media, we believe in crafting websites that are not just visually stunning but also strategically designed to drive results. Our team of creative experts merges cutting-edge design with intuitive user experiences to ensure your website captivates visitors and converts them into loyal customers. ",
    },
    {
      Img: onlineoarketingicon4,
      Title: "Standee",
      Text: "At Tulips Media, we believe in crafting websites that are not just visually stunning but also strategically designed to drive results. Our team of creative experts merges cutting-edge design with intuitive user experiences to ensure your website captivates visitors and converts them into loyal customers. ",
    },
    {
      Img: onlineoarketingicon4,
      Title: "Vehicle Branding",
      Text: "At Tulips Media, we believe in crafting websites that are not just visually stunning but also strategically designed to drive results. Our team of creative experts merges cutting-edge design with intuitive user experiences to ensure your website captivates visitors and converts them into loyal customers. ",
    },
    {
      Img: onlineoarketingicon4,
      Title: "Calender",
      Text: "At Tulips Media, we believe in crafting websites that are not just visually stunning but also strategically designed to drive results. Our team of creative experts merges cutting-edge design with intuitive user experiences to ensure your website captivates visitors and converts them into loyal customers. ",
    },
    {
      Img: onlineoarketingicon4,
      Title: "Diary Design",
      Text: "At Tulips Media, we believe in crafting websites that are not just visually stunning but also strategically designed to drive results. Our team of creative experts merges cutting-edge design with intuitive user experiences to ensure your website captivates visitors and converts them into loyal customers. ",
    },
    {
      Img: onlineoarketingicon4,
      Title: "Paper Ad",
      Text: "At Tulips Media, we believe in crafting websites that are not just visually stunning but also strategically designed to drive results. Our team of creative experts merges cutting-edge design with intuitive user experiences to ensure your website captivates visitors and converts them into loyal customers. ",
    },
    {
      Img: onlineoarketingicon4,
      Title: "Magazine",
      Text: "At Tulips Media, we believe in crafting websites that are not just visually stunning but also strategically designed to drive results. Our team of creative experts merges cutting-edge design with intuitive user experiences to ensure your website captivates visitors and converts them into loyal customers. ",
    },
    {
      Img: onlineoarketingicon4,
      Title: "Poster",
      Text: "At Tulips Media, we believe in crafting websites that are not just visually stunning but also strategically designed to drive results. Our team of creative experts merges cutting-edge design with intuitive user experiences to ensure your website captivates visitors and converts them into loyal customers. ",
    },
  ];
  return (
    <>
      <section className="py-5">
        <Container>
          <Row className="g-5">
            {DesignSolutionListData.map((design, index) => (
              <div
                className="col-lg-6 col-md-6 col-sm-12 col-xs-12"
                key={index}
              >
                <div className="crumina-module crumina-info-box info-box--standard-hover">
                  <div className="info-box-image">
                    <img
                      className="utouch-icon img-fluid"
                      alt="Solution"
                      src={design.Img}
                    />
                  </div>
                  <div className="info-box-content">
                    <h5 className="info-box-title">{design.Title}</h5>
                    <p className="info-box-text">{design.Text}</p>
                  </div>
                </div>
              </div>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
}
