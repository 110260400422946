import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import Cloud26 from "../../../Img/clouds26.png";

import logo1 from "../../../Img/technology/html.png";
import logo2 from "../../../Img/technology/css.png";
import logo3 from "../../../Img/technology/js.png";
import logo4 from "../../../Img/technology/bootstrap.png";
import logo5 from "../../../Img/technology/php.png";
import logo6 from "../../../Img/technology/react-js.png";
import logo7 from "../../../Img/technology/sql.png";
import logo8 from "../../../Img/technology/jquery.png";
import AOS from "aos";
export const TechnologyStack = () => {
  useEffect(() => {
    AOS.init({
      once: true,
      offset: 120,
    });
  }, []);
  const technologyStack = [
    {
      id: 1,
      img: logo1,
      title: "Html",
    },
    {
      id: 2,
      img: logo2,
      title: "CSS",
    },
    {
      id: 3,
      img: logo3,
      title: "Js",
    },
    {
      id: 4,
      img: logo4,
      title: "Bootstrap",
    },
    {
      id: 5,
      img: logo5,
      title: "Php",
    },
    {
      id: 6,
      img: logo6,
      title: "React",
    },
    {
      id: 7,
      img: logo7,
      title: "MySql",
    },
    {
      id: 8,
      img: logo8,
      title: "jQuery",
    },
  ];
  return (
    <section className="bg-blue-lighteen py-5">
      <Container>
        <Row className="mb-5">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div
              className="sec-title"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="500"
            >
              <img className="cloud" src={Cloud26} alt="cloud" />
              <h2>
                <span>Our technology stack</span>
              </h2>
            </div>
          </div>
        </Row>
        <Row className="g-5">
          {technologyStack.map((data) => (
            <div
              className="col-lg-2 col-md-3 col-sm-4"
              key={data.id}
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="500"
            >
              <img src={data.img} alt={data.title} title={data.title} />
            </div>
          ))}
        </Row>
      </Container>
    </section>
  );
};
