import React from "react";
import { Container, Row } from "react-bootstrap";

import onlineoarketingicon1 from "../../../Img/icons/seo-icon.png";
import onlineoarketingicon2 from "../../../Img/icons/sem-icon.png";
import onlineoarketingicon3 from "../../../Img/icons/e-mail-marketing.png";
import onlineoarketingicon4 from "../../../Img/icons/smm-icon.png";

export default function OnlineMarketing() {
  const onlinemarketingData = [
    {
      Img: onlineoarketingicon1,
      Title: "Search Engine Optimization",
      Text: "At Tulips Media, we believe in crafting websites that are not just visually stunning but also strategically designed to drive results. Our team of creative experts merges cutting-edge design with intuitive user experiences to ensure your website captivates visitors and converts them into loyal customers. ",
    },
    {
      Img: onlineoarketingicon2,
      Title: "Search Engine Marketing",
      Text: "At Tulips Media, we believe in crafting websites that are not just visually stunning but also strategically designed to drive results. Our team of creative experts merges cutting-edge design with intuitive user experiences to ensure your website captivates visitors and converts them into loyal customers. ",
    },
    {
      Img: onlineoarketingicon3,
      Title: "E-Mail Marketing",
      Text: "At Tulips Media, we believe in crafting websites that are not just visually stunning but also strategically designed to drive results. Our team of creative experts merges cutting-edge design with intuitive user experiences to ensure your website captivates visitors and converts them into loyal customers. ",
    },
    {
      Img: onlineoarketingicon4,
      Title: "Social Media Marketing",
      Text: "At Tulips Media, we believe in crafting websites that are not just visually stunning but also strategically designed to drive results. Our team of creative experts merges cutting-edge design with intuitive user experiences to ensure your website captivates visitors and converts them into loyal customers. ",
    },
  ];
  return (
    <>
      <section className="onlineoarketing py-5">
        <Container>
          <Row>
            <div className="col-lg-12">
              <div className="crumina-module crumina-module-img-bottom">
                <div className="crumina-module crumina-heading position-relative">
                  <div className="heading-left clum-6">
                    <h2 className="heading-title">
                      <span>Online Marketing</span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </Row>
          <Row className="g-5">
            {onlinemarketingData.map((marketing, index) => (
              <div
                className="col-lg-6 col-md-6 col-sm-12 col-xs-12"
                key={index}
              >
                <div className="crumina-module crumina-info-box info-box--standard-hover">
                  <div className="info-box-image">
                    <img
                      className="utouch-icon img-fluid"
                      alt="Solution"
                      src={marketing.Img}
                    />
                  </div>
                  <div className="info-box-content">
                    <h5 className="info-box-title">{marketing.Title}</h5>
                    <p className="info-box-text">{marketing.Text}</p>
                  </div>
                </div>
              </div>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
}
