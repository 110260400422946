import React from "react";
import HomeWhatWeDo from "./HomeWhatWeDo";
import { HomeStatics } from "./HomeStatics";
import HomeClients from "./HomeClients";
// import { PortFolio } from "./PortFolio";
import HomeWelcome from "./HomeWelcome";
import HomeSlider from "./HomeSlider";
import "./Home.css";
// import { HomeCaseStudy } from "./HomeCaseStudy";
import { Helmet } from "react-helmet-async";
import HomeTestimonial from "./HomeTestimonial";
import HomeCaseStudy2 from "./HomeCaseStudy2";

export default function Home() {
  return (
    <>
      <Helmet>
        <title>
          Tulips Media | a creative and technological solution agency
        </title>
        <meta
          name="description"
          content="creative and technological solution agency"
        />
      </Helmet>

      <HomeSlider />
      <HomeWelcome />
      <HomeWhatWeDo />
      <HomeStatics />
      {/* <PortFolio /> */}
      {/* <HomeCaseStudy /> */}
      <HomeCaseStudy2 />
      <HomeClients />
      <HomeTestimonial />
    </>
  );
}
