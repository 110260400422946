import React from "react";
import { Container, Row } from "react-bootstrap";
import Cloud8 from "../../../Img/clouds8.png";

export default function DigitalMarketingProfileContent() {
  return (
    <>
      <section className="content-block">
        <Container>
          <Row>
            <div className="col-lg-12">
              <div className="sec-title">
                <img className="cloud" src={Cloud8} alt="cloud" />
                <h2>
                  <span>
                    Empower your brand's digital journey with Tulips Media's
                    innovative strategies and engaging content
                  </span>
                </h2>
              </div>
            </div>
          </Row>
          <Row>
            <div className="col-lg-12">
              <div className="heading-text">
                In today's digital age, establishing a strong online presence is
                essential for businesses to thrive. At Tulips Media, we
                understand the significance of effective digital marketing in
                reaching your target audience, driving engagement, and
                ultimately boosting your bottom line. With our comprehensive
                suite of digital marketing services, we help businesses like
                yours stand out in the crowded digital landscape.
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
}
