import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import Cloud8 from "../../../Img/clouds8.png";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

import arrowsm from "../../../Img/arrow/arrow-sm-1.png";
import arrowlg from "../../../Img/arrow/arrow-lg-1.png";
import dotarrow from "../../../Img/arrow/dot-arrow-svgrepo-com.png";
import AOS from "aos";

import img1 from "../../../Img/service-icons/dedicated-hiring/understanding-your-needs.png";
import img2 from "../../../Img/service-icons/dedicated-hiring/talent-sourcing.png";
import img3 from "../../../Img/service-icons/dedicated-hiring/screening-assessment.png";
import img4 from "../../../Img/service-icons/dedicated-hiring/shortlisting-candidates.png";
import img5 from "../../../Img/service-icons/dedicated-hiring/interview-coordination.png";
import img6 from "../../../Img/service-icons/dedicated-hiring/onboarding-support.png";

export const DedicatedHiringProcess = () => {
  useEffect(() => {
    AOS.init({
      once: true,
      offset: 120,
    });
  }, []);
  const workesData = [
    {
      id: 1,
      SubTitle: "01",
      Img: img1,
      Title: "Understanding your needs",
      Color: "bg-secondary-color",
      Text: "We begin by collaborating with you to understand your company culture, values, and specific hiring requirements. This ensures we align our search with your goals.",
    },
    {
      id: 2,
      SubTitle: "02",
      Img: img2,
      Title: "Talent sourcing",
      Color: "bg-blue",
      Text: "Utilising our extensive network and advanced sourcing techniques, we identify potential candidates who not only meet the technical requirements but also fit your organisational culture.",
    },
    {
      id: 3,
      SubTitle: "03",
      Img: img3,
      Title: "Screening and assessment",
      Color: "bg-blue-light",
      Text: "Our team conducts thorough screenings, including interviews and skill assessments, to evaluate candidates’ qualifications and compatibility.",
    },
    {
      id: 4,
      SubTitle: "04",
      Img: img4,
      Title: "Shortlisting candidates",
      Color: "bg-orange-light",
      Text: "We present you with a curated list of top candidates, along with detailed profiles and our recommendations, to simplify your decision-making process.",
    },
    {
      id: 5,
      SubTitle: "05",
      Img: img5,
      Title: "Interview coordination",
      Color: "bg-red",
      Text: "We manage the interview process, coordinating schedules and facilitating communication between you and the candidates to ensure a smooth experience.",
    },
    {
      id: 6,
      SubTitle: "06",
      Img: img6,
      Title: "Onboarding support",
      Color: "bg-green",
      Text: "Once you’ve made your selection, we assist with the onboarding process to ensure a seamless transition for both you and the new hire.",
    },
  ];
  return (
    <>
      <section className="crumina-module crumina-module-slider section-padding dedicated-hiring-pro-bg">
        <Container>
          <Row className="mb-5">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div
                className="sec-title"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                <img className="cloud" src={Cloud8} alt="cloud" />
                <h2>
                  <span>Our dedicated hiring process</span>
                </h2>
              </div>
            </div>
          </Row>

          <Row>
            <div className="col-lg-12 col-sm-12 col-md-12">
              <Swiper
                className="swiper-container swiper-swiper-unique-id-0 swiper-container-horizontal pagination-bottom"
                id="swiper-unique-id-0"
                spaceBetween={30}
                loop={true}
                navigation={{
                  nextEl: ".btn-next",
                  prevEl: ".btn-prev",
                }}
                modules={[Navigation]}
                slidesPerView={1}
                slidesPerGroup={3} // Default for larger screens
                speed={1000} // Set transition speed to 1000ms (1 second)
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                  },
                  768: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                  },
                  1024: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                  },
                }}
              >
                {workesData.map((data) => (
                  <SwiperSlide key={data.id}>
                    <div className="crumina-module crumina-info-box info-box--time-line">
                      <div className={`info-box-image ${data.Color}`}>
                        <img
                          className="utouch-icon"
                          src={data.Img}
                          alt="flag"
                        />
                        <div className="utouch-icon utouch-icon-dot-arrow time-line-arrow d-none">
                          <img
                            src={dotarrow}
                            className="img-fluid"
                            alt="dot arrow"
                          />
                        </div>
                      </div>

                      <div className="info-box-content">
                        <h5 className="mb-0">
                          {data.SubTitle}
                          <br />
                        </h5>
                        <p className="h6 info-box-title mt-0">{data.Title}</p>
                        <p className="info-box-text">{data.Text}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}

                <div className="btn-slider-wrap navigation-center-bottom">
                  <div className="btn-prev">
                    <img
                      src={arrowlg}
                      className="img-fluid arrow-lg utouch-icon icon-hover utouch-icon-arrow-right-1"
                      alt="Arrow"
                      style={{ transform: "rotate(180deg)" }}
                    />
                    <img
                      src={arrowsm}
                      className="img-fluid arrow-sm utouch-icon utouch-icon-arrow-right1"
                      alt="Arrow"
                      style={{ transform: "rotate(180deg)" }}
                    />
                  </div>

                  <div className="btn-next">
                    <img
                      src={arrowlg}
                      className="img-fluid arrow-lg utouch-icon icon-hover utouch-icon-arrow-right-1"
                      alt="Arrow"
                    />
                    <img
                      src={arrowsm}
                      className="img-fluid arrow-sm utouch-icon utouch-icon-arrow-right1"
                      alt="Arrow"
                    />
                  </div>
                </div>
              </Swiper>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
};
