import React, { useEffect } from "react";
import icon1 from "../../Img/icons/web-design.png";
import icon2 from "../../Img/icons/mobile-app.png";
import icon3 from "../../Img/icons/dedicated-hiring.png";
import icon4 from "../../Img/icons/digital-marketing.png";
import icon5 from "../../Img/icons/graphic-design.png";
import Cloud2 from "../../Img/clouds9.png";
import Cloud8 from "../../Img/clouds8.png";
import { Link } from "react-router-dom";
import arrowsm from "../../Img/arrow/arrow-sm-1.png";
import arrowlg from "../../Img/arrow/arrow-lg-1.png";
import { Row } from "react-bootstrap";
import AOS from "aos";
import WebConfig from "../../WebConfig";

const HomeWhatWeDo = () => {
  const webUrl = WebConfig.ConfigUrl;

  useEffect(() => {
    AOS.init({
      once: true,
      offset: 120,
    });
  }, []);

  const serviceData = [
    {
      id: 1,
      icon: icon1,
      title: "Web Design Development",
      text: "We specialise in creating stunning, user-friendly websites that not only capture your brand's essence but also drive results. Our expert team combines innovative design with cutting-edge technology to build websites tailored to your unique needs.",
      link: `${webUrl}service/web-design-development`,
    },
    {
      id: 2,
      icon: icon1,
      cloud: Cloud2,
      title: "Web Application Development",
      text: "We have expertise in developing websites using a variety of technologies, including HTML, CSS, JavaScript, and popular frameworks like React. Our team is proficient in building responsive, scalable, and user-friendly websites tailored to your specific needs.",
      link: `${webUrl}service/web-application-development`,
    },
    {
      id: 3,
      icon: icon2,
      title: "Mobile App Development",
      text: "We offer mobile app development services for iOS and Android platforms, using technologies like Php and Java. Whether you need a standalone app or integration with your existing website, we can create a seamless mobile experience for your users.",
      link: `${webUrl}service/mobile-app-development`,
    },
    {
      id: 4,
      icon: icon4,
      cloud: Cloud2,
      title: "Digital Marketing",
      text: "In today's digital age, establishing a strong online presence is essential for businesses to thrive. At Tulips Media, we understand the significance of effective digital marketing in reaching your target audience, driving engagement, and ultimately boosting your bottom line.",
      link: `${webUrl}service/digital-marketing`,
    },
    {
      id: 5,
      icon: icon5,
      title: "Graphic Design",
      text: "Graphic design is the art of communication through visually perceivable content. It combines creativity, technology, and strategic thinking to convey messages, evoke emotions, and engage audiences. Whether it’s a striking logo, an eye-catching advertisement, etc...",
      link: `${webUrl}service/graphic-design`,
    },
    {
      id: 6,
      icon: icon3,
      cloud: Cloud2,
      title: "Domains & Hosting",
      text: "Dedicated hiring is a customised recruitment solution that allows businesses to tap into a pool of specialised talent. By partnering with us, you gain access to a dedicated team of professionals who understand your industry, culture, and specific needs.",
      link: `${webUrl}service/domains-hosting`,
    },
  ];

  return (
    <section className="bg-white section-padding">
      <div className="container">
        <Row className="mb-5">
          <div className="col-lg-12">
            <div
              className="sec-title"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="500"
            >
              <img className="cloud" src={Cloud8} alt="cloud" />
              <h2>
                <span>What we do</span>
              </h2>
            </div>
          </div>
        </Row>
        <Row className="gy-5">
          {serviceData.map((service, index) => (
            <div
              key={service.id}
              className="col-lg-4 col-md-6 col-sm-12 col-xs-12"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="500"
            >
              <div className="crumina-module crumina-info-box info-box--standard-hover">
                <div className="info-box-image">
                  <img
                    className="utouch-icon img-fluid"
                    src={service.icon}
                    alt={service.title}
                  />
                </div>
                <div className="info-box-content">
                  <Link
                    to={service.link ? service.link : "#"}
                    className="h5 info-box-title"
                  >
                    {service.title}
                  </Link>
                  <p className="info-box-text">{service.text}</p>

                  <Link
                    to={service.link ? service.link : "#"}
                    className="btn-next d-inline-block"
                  >
                    <img
                      src={arrowlg}
                      className="img-fluid arrow-lg utouch-icon icon-hover utouch-icon-arrow-right-1"
                      alt="Arrow"
                    />
                    <img
                      src={arrowsm}
                      className="img-fluid arrow-sm utouch-icon utouch-icon-arrow-right1"
                      alt="Arrow"
                    />
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </Row>
      </div>
    </section>
  );
};

export default HomeWhatWeDo;
