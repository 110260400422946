import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import Cloud26 from "../../../Img/clouds26.png";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

import arrowsm from "../../../Img/arrow/arrow-sm-1.png";
import arrowlg from "../../../Img/arrow/arrow-lg-1.png";
import dotarrow from "../../../Img/arrow/dot-arrow-svgrepo-com.png";
import AOS from "aos";

import discovery from "../../../Img/service-icons/graphic-design/discovery.png";
import conceptdevelopment from "../../../Img/service-icons/graphic-design/concept-development.png";
import designexecution from "../../../Img/service-icons/graphic-design/design-execution.png";
import feedbackrevisions from "../../../Img/service-icons/graphic-design/feedback-revisions.png";
import finaldelivery from "../../../Img/service-icons/graphic-design/final-delivery.png";
export const GraphicDesignProcess = () => {
  useEffect(() => {
    AOS.init({
      once: true,
      offset: 120,
    });
  }, []);
  const workesData = [
    {
      id: 1,
      Img: discovery,
      SubTitle: "01",
      Title: "Discovery",
      Color: "bg-secondary-color",
      Text: "We begin by understanding your goals, target audience, and brand vision through comprehensive consultations.",
    },
    {
      id: 2,
      Img: conceptdevelopment,
      SubTitle: "02",
      Title: "Concept development",
      Color: "bg-blue",
      Text: "Our creative team brainstorms and develops multiple design concepts tailored to your needs.",
    },
    {
      id: 3,
      Img: designexecution,
      SubTitle: "03",
      Title: "Design execution",
      Color: "bg-blue-light",
      Text: "Once a direction is chosen, we refine and execute the design, paying attention to every detail.",
    },
    {
      id: 4,
      Img: feedbackrevisions,
      SubTitle: "04",
      Title: "Feedback and revisions",
      Color: "bg-orange-light",
      Text: "We value your input and work closely with you to make necessary adjustments, ensuring the final design meets your expectations.",
    },
    {
      id: 5,
      Img: finaldelivery,
      SubTitle: "05",
      Title: "Delivery",
      Color: "bg-red",
      Text: "Upon approval, we deliver high-quality files suitable for both print and digital use.",
    },
  ];
  return (
    <>
      <section className="crumina-module crumina-module-slider bg-blue-lighteen section-padding-skew section-bg-skew graphic-design-process-bg">
        <Container>
          <Row className="mb-5">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div
                className="sec-title"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                <img className="cloud" src={Cloud26} alt="cloud" />
                <h2>
                  <span>Our process</span>
                </h2>
              </div>
            </div>
          </Row>

          <Row>
            <div className="col-lg-12 col-sm-12 col-md-12">
              <Swiper
                className="swiper-container swiper-swiper-unique-id-0 swiper-container-horizontal pagination-bottom"
                id="swiper-unique-id-0"
                spaceBetween={30}
                loop={true}
                navigation={{
                  nextEl: ".btn-next",
                  prevEl: ".btn-prev",
                }}
                modules={[Navigation]}
                slidesPerView={1} // Default for larger screens
                speed={1000} // Set transition speed to 1000ms (1 second)
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  1024: {
                    slidesPerView: 3,
                  },
                }}
              >
                {workesData.map((data) => (
                  <SwiperSlide key={data.id}>
                    <div className="crumina-module crumina-info-box info-box--time-line">
                      <div className={`info-box-image ${data.Color}`}>
                        <img
                          className="utouch-icon"
                          src={data.Img}
                          alt="flag"
                        />
                        <div className="utouch-icon utouch-icon-dot-arrow time-line-arrow d-none">
                          <img
                            src={dotarrow}
                            className="img-fluid"
                            alt="dot arrow"
                          />
                        </div>
                      </div>

                      <div className="info-box-content">
                        <h5 className="mb-0">{data.SubTitle}</h5>
                        <p className="h6 info-box-title mt-0">{data.Title}</p>
                        <p className="info-box-text">{data.Text}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}

                <div className="btn-slider-wrap navigation-center-bottom">
                  <div className="btn-prev">
                    <img
                      src={arrowlg}
                      className="img-fluid arrow-lg utouch-icon icon-hover utouch-icon-arrow-right-1"
                      alt="Arrow"
                      style={{ transform: "rotate(180deg)" }}
                    />
                    <img
                      src={arrowsm}
                      className="img-fluid arrow-sm utouch-icon utouch-icon-arrow-right1"
                      alt="Arrow"
                      style={{ transform: "rotate(180deg)" }}
                    />
                  </div>

                  <div className="btn-next">
                    <img
                      src={arrowlg}
                      className="img-fluid arrow-lg utouch-icon icon-hover utouch-icon-arrow-right-1"
                      alt="Arrow"
                    />
                    <img
                      src={arrowsm}
                      className="img-fluid arrow-sm utouch-icon utouch-icon-arrow-right1"
                      alt="Arrow"
                    />
                  </div>
                </div>
              </Swiper>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
};
