import React from "react";
import { Container, Row } from "react-bootstrap";

export const ContactMap = () => {
  return (
    <>
      <section className="map-section p-0 m-0">
        <Container fluid className="p-0 m-0">
          <Row className="p-0 m-0">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 m-0">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.3250154928746!2d76.96005817570511!3d11.014223154761131!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba858ffdc6db945%3A0x628bcb595d813383!2sTulips%20Media!5e0!3m2!1sen!2sin!4v1709036412645!5m2!1sen!2sin"
                width="100%"
                height="450"
                title="Google Map"
              ></iframe>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
};
