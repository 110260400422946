import React, { useEffect } from "react";
import { Row, Container } from "react-bootstrap";
import AbourPic from "../../Img/about-pic.jpg";
import AOS from "aos";
import Cloud8 from "../../Img/clouds8.png";

export const AboutContent = () => {
  useEffect(() => {
    AOS.init({
      once: true,
      offset: 120,
    });
  }, []);
  return (
    <>
      <section className="about-bg section-padding pb-0">
        <Container>
          <Row>
            {/* <div className="crumina-module crumina-module-img-bottom"> */}
            <div className="crumina-module">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="crumina-module crumina-heading position-relative">
                  <div className="sec-title mb-5 d-none">
                    <img className="cloud" src={Cloud8} alt="cloud" />
                    <h2>
                      <span>About us</span>
                    </h2>
                  </div>

                  <div
                    data-aos="fade-up"
                    data-aos-duration="1200"
                    data-aos-delay="500"
                  >
                    <blockquote className="mb-0">
                      <p className="mb-0" style={{ color: "#00467a" }}>
                        <i>
                          Blossoming dreams like delicate petals dancing in the
                          wind, Tulips Media blooms with the essence of
                          creativity and the fragrance of innovation that works
                          for the future!
                        </i>
                      </p>
                    </blockquote>
                  </div>

                  <div
                    className="heading-text"
                    data-aos="fade-up"
                    data-aos-duration="1200"
                    data-aos-delay="500"
                  >
                    At Tulips Media, we're not just a company; we're a creative
                    powerhouse fueled by innovation and driven by passion. With
                    a seamless blend of cutting-edge technology and boundless
                    imagination, we craft brand experiences that transcend the
                    ordinary. With each endeavour, we nurture growth, cultivate
                    beauty, and celebrate the artistry of technology.
                  </div>
                  <div
                    className="heading-text"
                    data-aos="fade-up"
                    data-aos-duration="1200"
                    data-aos-delay="500"
                  >
                    Our team of visionary minds is dedicated to pushing the
                    boundaries of possibility, transforming ideas into reality,
                    and shaping the future of digital storytelling. From
                    stunning visuals to immersive experiences, we're here to
                    redefine what's possible in the technological landscape. Our
                    team expertise includes; Media Psychologist, Digital
                    Designers, Strategist, Marketing Specialist, Top-Notch
                    Technology Architect and experienced programmers.
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <img
                  className="img-bottom img-fluid"
                  src={AbourPic}
                  alt="man"
                />
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
};
