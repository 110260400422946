import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import Cloud8 from "../../../Img/clouds8.png";
import AOS from "aos";
export default function WebAppProfileContent() {
  useEffect(() => {
    AOS.init({
      once: true,
      offset: 120,
    });
  }, []);
  return (
    <>
      <section className="content-block">
        <Container>
          <Row>
            <div className="col-lg-12">
              <div className="sec-title">
                <img className="cloud" src={Cloud8} alt="cloud" />
                <h2>
                  <span>Mobile app development company in India</span>
                </h2>
              </div>
            </div>
          </Row>
          <Row>
            <div className="col-lg-12">
              <div
                className="heading-text"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                At Tulips Media, we're passionate about harnessing the power of
                mobile technology to drive innovation and transform businesses.
                Our mobile app development services are designed to create
                immersive, user-centric experiences that captivate audiences and
                drive results.
              </div>
              <div
                className="heading-text"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                With a focus on creativity, functionality, and user experience,
                we work closely with our clients to bring their app ideas to
                life. From initial concept to final deployment, our team of
                experienced developers and designers is dedicated to delivering
                solutions that exceed expectations.
              </div>
              <div
                className="heading-text"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                Whether you're looking to launch a native iOS app, tap into the
                Android market, or explore cross-platform development, we have
                the expertise and resources to make it happen. Our commitment to
                quality, innovation, and client satisfaction sets us apart as a
                trusted partner for mobile app development.
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
}
