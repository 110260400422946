import React from "react";
import { Container, Row } from "react-bootstrap";

export default function SolutionContent() {
  return (
    <>
      <section className="content-block">
        <Container>
          <Row>
            <div className="col-lg-12">
              <div className="crumina-module crumina-module-img-bottom">
                <div className="crumina-heading position-relative">
                  <div className="heading-left clum-6 mb-0">
                    <h2 className="heading-title">
                      <span>Digital Solution</span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="heading-text">
                Welcome to Tulips Media, where we specialize in providing
                comprehensive web solutions tailored to meet your digital needs.
                From captivating web design to robust development, intuitive
                mobile apps, and seamless UI/UX design, we're dedicated to
                elevating your online presence and user experience. Let us
                transform your vision into reality. With a team of creative
                professionals at your service, we pride ourselves on delivering
                innovative solutions that resonate with your target audience.
                Whether you're a startup aiming to make a splash or an
                established brand seeking a digital facelift, we're committed to
                exceeding your expectations. Harness the power of the web with
                Tulips Media, where every pixel counts towards your success.
                Let's embark on this journey together and unlock the full
                potential of your online venture.
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
}
