import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import AOS from "aos";
import Cloud8 from "../../../Img/clouds8.png";

export default function STCourierContent() {
  useEffect(() => {
    AOS.init({
      once: true,
      offset: 120,
    });
  }, []);
  return (
    <>
      <section className="content-block">
        <Container>
          <Row>
            <div className="col-lg-12">
              <div className="sec-title">
                <img className="cloud" src={Cloud8} alt="cloud" />
                <h2>
                  <span>ST Courier Management System</span>
                </h2>
              </div>
            </div>
            <div className="col-lg-12">
              <div
                className="heading-text"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="50"
              >
                ST Courier, the largest courier company required a solution to
                manage the entire courier management system from booking to
                delivery completely online. Tulips Media team analyzed the
                entire work-flow of the company and provided them with various
                solutions such as mobile and web applications to ease and
                support the courier management system.
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
}
