import React from "react";
import "./Contact.css";
import { ContactContainer } from "./ContactContainer";
import { ContactMap } from "./ContactMap";
import ContactBanner from "./ContactBanner";
import { Helmet } from "react-helmet-async";

export const Contact = () => {
  return (
    <>
      <Helmet>
        <title>Reach us | Tulips Media</title>
        <meta
          name="description"
          content="creative and technological solution agency"
        />
      </Helmet>

      <ContactBanner />
      <ContactContainer />
      <ContactMap />
    </>
  );
};
