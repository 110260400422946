import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import Cloud8 from "../../../Img/clouds8.png";
import AOS from "aos";

import img1 from "../../../Img/service-icons/domains-hosting/domain-registration.svg";
import img2 from "../../../Img/service-icons/domains-hosting/domain-transfer.svg";
import img3 from "../../../Img/service-icons/domains-hosting/privacy-protection.svg";
export default function DomainsServices() {
  useEffect(() => {
    AOS.init({
      once: true,
      offset: 120,
    });
  }, []);
  const DomainsServicesData = [
    {
      id: 1,
      Img: img1,
      Title: "Domain registration",
      Text: "Secure your online identity with our fast and straight forward domain registration process. Choose from various extensions and manage your domains effortlessly through our user-friendly control panel.",
    },
    {
      id: 2,
      Img: img2,
      Title: "Domain transfer",
      Text: "Looking to switch your domain to a better service? Our domain transfer process is seamless and straightforward, with minimal downtime.",
    },
    {
      id: 3,
      Img: img3,
      Title: "WHOIS privacy protection",
      Text: "Keep your personal information safe from prying eyes with our WHOIS privacy protection service, ensuring your details remain confidential.",
    },
  ];

  return (
    <>
      <section className="section-padding ">
        <Container>
          <Row className="mb-5">
            <div className="col-lg-12">
              <div
                className="sec-title"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                <img className="cloud" src={Cloud8} alt="cloud" />
                <h2>
                  <span>Our domain services</span>
                </h2>
              </div>
            </div>
          </Row>
          <Row className="g-3">
            {DomainsServicesData.map((DomainsServicesData) => (
              <div
                className="col-lg-4 col-md-4 col-sm-6 col-xs-12"
                key={DomainsServicesData.id}
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                <div className="crumina-module crumina-info-box info-box--standard-hover">
                  <div className="info-box-image">
                    <img
                      className="utouch-icon img-fluid"
                      alt="Services"
                      src={DomainsServicesData.Img}
                    />
                  </div>
                  <div className="info-box-content">
                    <h5 className="info-box-title">
                      {DomainsServicesData.Title}
                    </h5>
                    <p className="info-box-text">{DomainsServicesData.Text}</p>
                  </div>
                </div>
              </div>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
}
