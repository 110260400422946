import React from "react";
import { Container, Row } from "react-bootstrap";

import img from "../../../Img/demo-screen-1.png";

export default function STCourierFeatures() {
  return (
    <>
      <section className="bg-blue-lighteen section-padding-skew section-bg-skew service-bg-1">
        <Container>
          <Row>
            {/* <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 negative-margin-bottom80 d-none">
              <div className="crumina-module crumina-double-image">
                <img
                  src="https://html.crumina.net/html-utouch/img/image6.png"
                  alt="img"
                  className="img-fluid"
                />
                <img
                  src="https://html.crumina.net/html-utouch/img/image7.png"
                  alt="img"
                  className="img-fluid"
                />
              </div>
            </div> */}
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <img src={img} className="img-fluid" alt="img" />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 pb80">
              <div className="crumina-module crumina-heading">
                <h3 className="heading-title">How It Works</h3>
                <div className="heading-text">
                  ST Courier, the largest courier company required a solution to
                  manage the entire courier management system from booking to
                  delivery completely online. Tulips Media team analyzed the
                  entire work-flow of the company and provided them with various
                  solutions such as mobile and web applications to ease and
                  support the courier management system.
                </div>
              </div>
              <ul className="list list--standard">
                <li>
                  <i className="bi bi-check pe-2"></i>Weighing scale integration
                </li>
                <li>
                  <i className="bi bi-check pe-2"></i>Bar-code reader in mobile
                  app
                </li>
                <li>
                  <i className="bi bi-check pe-2"></i>Signature in mobile
                </li>
                <li>
                  <i className="bi bi-check pe-2"></i>Bluetooth based printing
                  from mobile
                </li>
                <li>
                  <i className="bi bi-check pe-2"></i>Delivery GPS location
                </li>
                <li>
                  <i className="bi bi-check pe-2"></i>Personalized Dashboard for
                  departments and MD
                </li>
              </ul>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
}
