import React from "react";
import STCourierContent from "./STCourierContent";
import STCourierFeatures from "./STCourierFeatures";
import STCourierScreenshots from "./STCourierScreenshots";
import "../../Casestudy/Casestudy.css";
import "../CasestudyDetails.css";
import STCourierBanner2 from "./STCourierBanner2";

export default function STCourier() {
  return (
    <>
      <STCourierBanner2 />
      <STCourierContent />
      <STCourierFeatures />
      <STCourierScreenshots />
    </>
  );
}
