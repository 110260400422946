import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";

import clouds26 from "../../Img/clouds26.png";
import creativewebsite from "../../Img/icons/creative-website.png";

import AOS from "aos";
export default function SuccessStories() {
  useEffect(() => {
    AOS.init({
      once: true,
      offset: 120,
    });
  }, []);
  const successdatalist = [
    {
      id: 1,
      Img: creativewebsite,
      Title: "Innovative campaigns",
      Desc: "Dive into our creative marketing strategies that drove impressive results for various brands.",
    },
    {
      id: 2,
      Img: creativewebsite,
      Title: "Brand transformations",
      Desc: "Learn how we revitalized client identities to resonate with their target audiences and stand out in competitive markets.",
    },
    {
      id: 3,
      Img: creativewebsite,
      Title: "Digital solutions",
      Desc: "See how our web development and design projects enhanced user experience and engagement for our clients.",
    },
    {
      id: 4,
      Img: creativewebsite,
      Title: "Social impact",
      Desc: "Explore initiatives where we collaborated with organizations to create meaningful change through impactful media.",
    },
  ];
  return (
    <>
      <section className="why-graphic-design section-padding-skew section-bg-skew bg-blue-lighteen service-bg-1">
        <Container>
          <Row className="mb-5">
            <div className="col-lg-12">
              <div
                className="sec-title"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                <img className="cloud" src={clouds26} alt="cloud" />
                <h2>
                  <span>Discover our success stories</span>
                </h2>
              </div>
            </div>
          </Row>
          <Row className="g-3 teammembers-wrap">
            {successdatalist.map((successdata) => (
              <div
                className="col-lg-6 col-md-6 col-sm-6 col-xs-6"
                key={successdata.id}
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                <div className="crumina-module crumina-teammembers-item icon_box_left">
                  <div className="teammembers-thumb">
                    <img src={successdata.Img} alt={successdata.Title} />
                  </div>

                  <div className="teammember-content">
                    <div className="teammembers-item-prof">
                      {successdata.Title}
                    </div>

                    <p>{successdata.Desc}</p>
                  </div>
                </div>
              </div>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
}
