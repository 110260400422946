import React from "react";
import { AboutContent } from "./AboutContent";
import "./About.css";
import WePlant from "./WePlant";
import AboutBanner from "./AboutBanner";
import { CallToAction } from "../Template/CTA/CallToAction";
import { HowWeWork3 } from "./HowWeWork3";
import { Helmet } from "react-helmet-async";

export const About = () => {
  return (
    <>
      <Helmet>
        <title>About Us | Tulips Media</title>
        <meta
          name="description"
          content="creative and technological solution agency"
        />
      </Helmet>
      <AboutBanner />
      <AboutContent />
      <HowWeWork3 />
      <WePlant />
      <CallToAction />
    </>
  );
};
