import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";

import Cloud8 from "../../../Img/clouds8.png";
import AOS from "aos";

import brandidentity from "../../../Img/service-icons/graphic-design/brand-identity.svg";
import printdesign from "../../../Img/service-icons/graphic-design/print-design.svg";
import digitaldesign from "../../../Img/service-icons/graphic-design/digital-design.svg";
import ilustration from "../../../Img/service-icons/graphic-design/ilustration.svg";
import packagingdesign from "../../../Img/service-icons/graphic-design/packaging-design.svg";

const GraphicDesignServices = () => {
  useEffect(() => {
    AOS.init({
      once: true,
      offset: 120,
    });
  }, []);
  const webDesignDevData = [
    {
      id: 1,
      Img: brandidentity,
      Title: "Brand identity",
      Text: "We help you develop a strong brand identity that connects with your emotions through identity or logos, colour palettes, and typography that reflect your values and resonate with your audience.",
    },
    {
      id: 2,
      Img: printdesign,
      Title: "Print design",
      Text: "From brochures to package design, our print design services ensure that your materials are visually appealing and aligned with your brand.",
    },
    {
      id: 3,
      Img: digitaldesign,
      Title: "Digital design",
      Text: "We create stunning graphics for websites, social media, and online advertisements that attract and retain users.",
    },
    {
      id: 4,
      Img: ilustration,
      Title: "Illustration",
      Text: "Custom illustrations can add a unique touch to your brand, making your content more memorable and engaging.",
    },
    {
      id: 5,
      Img: packagingdesign,
      Title: "Packaging design",
      Text: "We design packaging that not only protects your product but also communicates your brand story and captures consumer interest.",
    },
  ];
  return (
    <>
      <section className="section-padding pb-1">
        <Container>
          <Row className="mb-5">
            <div className="col-lg-12">
              <div
                className="sec-title"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                <img className="cloud" src={Cloud8} alt="cloud" />
                <h2>
                  <span>Our services</span>
                </h2>
              </div>
            </div>
          </Row>
          <Row className="g-5">
            {webDesignDevData.map((webdata) => (
              <div
                className="col-lg-4 col-md-4 col-sm-6 col-xs-12"
                key={webdata.id}
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                <div className="crumina-module crumina-info-box info-box--standard-hover">
                  <div className="info-box-image">
                    <img
                      className="utouch-icon img-fluid"
                      alt="Services"
                      src={webdata.Img}
                    />
                  </div>
                  <div className="info-box-content">
                    <h5 className="info-box-title">{webdata.Title}</h5>
                    <p className="info-box-text">{webdata.Text}</p>
                  </div>
                </div>
              </div>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default GraphicDesignServices;
