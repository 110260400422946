import React from "react";
import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import underlinewhite from "../../../Img/title-underline-white.png";

export const CTASolution = () => {
  return (
    <>
      <section className="cta-solution background-cover align-center pt180">
        <Container>
          <Row>
            <div className="col-lg-9 col-lg-offset-2 col-md-9 col-md-offset-2 col-sm-12 col-sm-offset-0 col-xs-12">
              <div className="crumina-module crumina-heading mb-0">
                <h2>Unlock the potential for your digital journey</h2>
                <img
                  src={underlinewhite}
                  className="img-fluid"
                  alt="underline"
                />
              </div>
            </div>
          </Row>
          <Row>
            <div className="col-lg-9 col-lg-offset-2 col-md-9 col-md-offset-2 col-sm-12 col-sm-offset-0 col-xs-12">
              <Link
                to="#"
                className="btn btn--blue hvr-bg-yellow hvr-white mt-5"
              >
                Request a Quote
              </Link>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
};
