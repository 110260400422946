import React from "react";
import { Container, Row } from "react-bootstrap";

import img from "../../../Img/demo-screen-1.png";

export default function AriviyalFeatures() {
  return (
    <>
      <section className="bg-blue-lighteen section-padding-skew section-bg-skew service-bg-1">
        <Container>
          <Row>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <img src={img} className="img-fluid" alt="img" />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 pb80">
              <div className="crumina-module crumina-heading">
                <h3 className="heading-title">How It Works</h3>
                <div className="heading-text">
                  Ariviyal publishing is a dynamic and emerging platform of
                  internationally peer- reviewed open access Journals across a
                  broad area of scientific disciplines. The Journals comes under
                  Ariviyal Publishing are guided by eminent editorial board
                  members. Apart from Journals, Ariviyal Publishing is also
                  committed to publish books within the fields of Science and
                  Engineering. Tulips Media created branding and
                  web-application.
                </div>
              </div>
              <ul className="list list--standard">
                <li>
                  <i className="bi bi-check pe-2"></i>Journal creation and
                  management
                </li>
                <li>
                  <i className="bi bi-check pe-2"></i>Manuscript creation and
                  reviews app
                </li>
                <li>
                  <i className="bi bi-check pe-2"></i>Editorials management
                </li>
                <li>
                  <i className="bi bi-check pe-2"></i>Publishing and approval
                </li>
                <li>
                  <i className="bi bi-check pe-2"></i>Notifications/Reminders
                </li>
              </ul>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
}
