import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";

import Cloud26 from "../../../Img/clouds26.png";
import AOS from "aos";

import img1 from "../../../Img/service-icons/domains-hosting/shared-hosting.svg";
import img2 from "../../../Img/service-icons/domains-hosting/vps-hosting.svg";
import img3 from "../../../Img/service-icons/domains-hosting/dedicated-hosting.svg";

const HostingSolutions = () => {
  useEffect(() => {
    AOS.init({
      once: true,
      offset: 120,
    });
  }, []);
  const solutiondatalist = [
    {
      id: 1,
      Img: img1,
      Title: "Shared hosting",
      Text: "Perfect for startups and small businesses, our shared hosting plans offer affordable pricing with great features, making it easy to launch your website.",
    },
    {
      id: 2,
      Img: img2,
      Title: "VPS hosting",
      Text: "For businesses that require more resources and control, our Virtual Private Server (VPS) hosting provides dedicated resources and enhanced performance.",
    },
    {
      id: 3,
      Img: img3,
      Title: "Dedicated hosting",
      Text: "Enjoy the ultimate performance and security with our dedicated hosting solutions, giving you full control over your server environment.",
    },
  ];
  return (
    <>
      <section className="section-padding bg-blue-lighteen">
        <Container>
          <Row className="mb-5">
            <div className="col-lg-12">
              <div
                className="sec-title"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                <img className="cloud" src={Cloud26} alt="cloud" />
                <h2>
                  <span>Our hosting solutions</span>
                </h2>
              </div>
            </div>
          </Row>
          <Row className="g-5">
            {solutiondatalist.map((solutiondata) => (
              <div
                className="col-lg-4 col-md-4 col-sm-6 col-xs-12"
                key={solutiondata.id}
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                <div className="crumina-module crumina-info-box info-box--standard-hover text-start">
                  <div className="info-box-image">
                    <img
                      className="utouch-icon img-fluid"
                      alt="Services"
                      src={solutiondata.Img}
                    />
                  </div>
                  <div className="info-box-content">
                    <h6 className="info-box-title">{solutiondata.Title}</h6>
                    <p className="info-box-text">{solutiondata.Text}</p>
                  </div>
                </div>
              </div>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default HostingSolutions;
