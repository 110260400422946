import React, { useEffect } from "react";
import Cloud8 from "../../Img/clouds8.png";
import { Container, Row } from "react-bootstrap";
import weseedpic2 from "../../Img/we-seed-pic-2.png";
import AOS from "aos";

const WePlant = () => {
  useEffect(() => {
    AOS.init({
      once: true,
      offset: 120,
    });
  }, []);
  return (
    <section className="we-seed-bg section-padding">
      <Container>
        <Row className="mb-5">
          <div className="col-lg-12">
            <div
              className="sec-title"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="500"
            >
              <img className="cloud" src={Cloud8} alt="cloud" />
              <h2>
                <span>We plant the seeds of</span>
              </h2>
            </div>
          </div>
        </Row>
        <Row className="pt80 we-seed-lg">
          <div
            className="col-lg-4 col-md-4"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="500"
          >
            <div className="we-plant-desc">
              <div className="we-plant-text we-plant-text-1 text-end">
                <p>Strategizing your ideas and businesses</p>
              </div>
              <div className="we-plant-text we-plant-text-2 text-end">
                <p>
                  Designing the thoughts of your products and services that
                  communicate effectively
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4">
            <div>
              <img src={weseedpic2} className="img-fluid" alt="We Seed Pic" />
            </div>
          </div>
          <div
            className="col-lg-4 col-md-4"
            data-aos="fade-left"
            data-aos-duration="1200"
            data-aos-delay="500"
          >
            <div className="we-plant-desc">
              <div className="we-plant-text we-plant-text-3">
                <p>
                  Creating brand experience to promote and place in the hearts
                  of your target audience
                </p>
              </div>
              <div className="we-plant-text we-plant-text-4">
                <p>Digitalizing the business with smart and simple solutions</p>
              </div>
            </div>
          </div>
        </Row>
        <Row className="we-seed-sm">
          <div
            className="col-sm-6"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="500"
          >
            <div className="seed-icons-desc">
              <div className="seeds seeds-icon-1"></div>
              <p>
                <strong>Strategizing</strong> your ideas and businesses
              </p>
            </div>
          </div>
          <div
            className="col-sm-6"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="500"
          >
            <div className="seed-icons-desc">
              <div className="seeds seeds-icon-2"></div>
              <p>
                Designing the thoughts of your products and services that
                communicate effectively
              </p>
            </div>
          </div>
          <div
            className="col-sm-6"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="500"
          >
            <div className="seed-icons-desc">
              <div className="seeds seeds-icon-3"></div>
              <p>
                Creating brand experience to promote and place in the hearts of
                your target audience
              </p>
            </div>
          </div>
          <div
            className="col-sm-6"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="500"
          >
            <div className="seed-icons-desc">
              <div className="seeds seeds-icon-4"></div>
              <p>Digitalizing the business with smart and simple solutions</p>
            </div>
          </div>
        </Row>
      </Container>
    </section>
  );
};

export default WePlant;
