// Define multiple base URLs as an object
const WebConfig = {
  // Local link
  // ConfigUrl: "/tulips-react",
  // ApiUrl: "http://localhost/Tulips-work/24-2025/tulips/api",

  // Server Link
  ConfigUrl: "/",
  ApiUrl: "https://tulipsmedia.com/webadmin/api",
};

export default WebConfig;
