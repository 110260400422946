import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import AOS from "aos";
import Cloud8 from "../../../Img/clouds8.png";

export default function AriviyalContent() {
  useEffect(() => {
    AOS.init({
      once: true,
      offset: 120,
    });
  }, []);
  return (
    <>
      <section className="content-block">
        <Container>
          <Row>
            <div className="col-lg-12">
              <div className="sec-title">
                <img className="cloud" src={Cloud8} alt="cloud" />
                <h2>
                  <span>Journal Publishing</span>
                </h2>
              </div>
            </div>
            <div className="col-lg-12">
              <div
                className="heading-text"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="50"
              >
                Ariviyal publishing is a dynamic and emerging platform of
                internationally peer- reviewed open access Journals across a
                broad area of scientific disciplines. The Journals comes under
                Ariviyal Publishing are guided by eminent editorial board
                members. Apart from Journals, Ariviyal Publishing is also
                committed to publish books within the fields of Science and
                Engineering. Tulips Media created branding and web-application.
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
}
