import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";

import clouds26 from "../../../Img/clouds26.png";
import AOS from "aos";

import img1 from "../../../Img/service-icons/graphic-design/first-impressions.svg";
import img2 from "../../../Img/service-icons/graphic-design/enhances-brand-identity.svg";
import img3 from "../../../Img/service-icons/graphic-design/user-experience.svg";
import img4 from "../../../Img/service-icons/graphic-design/engages-audiences.svg";
export default function WhyGraphicDesign() {
  useEffect(() => {
    AOS.init({
      once: true,
      offset: 120,
    });
  }, []);
  const whyusdatalist = [
    {
      id: 1,
      Img: img1,
      Title: "First impressions matter",
      Desc: "In a world saturated with information, your visuals can make or break a first impression. Strong graphic design helps establish credibility and trust.",
    },
    {
      id: 2,
      Img: img2,
      Title: "Enhances brand identity",
      Desc: "Consistent and thoughtful design helps to create a cohesive brand identity. It ensures that your audience recognizes and connects with your brand across various platforms.",
    },
    {
      id: 3,
      Img: img3,
      Title: "Improves user experience",
      Desc: "Good design is not just about aesthetics; it’s about usability. Thoughtful graphic design enhances user experience, making information easier to digest and navigate.",
    },
    {
      id: 4,
      Img: img4,
      Title: "Engages audiences",
      Desc: "Eye-catching graphics can capture attention and keep your audience engaged. Effective design tells a story, guiding viewers through your message seamlessly.",
    },
  ];
  return (
    <>
      <section className="why-graphic-design section-padding-skew section-bg-skew bg-blue-lighteen service-bg-1">
        <Container>
          <Row className="mb-5">
            <div className="col-lg-12">
              <div
                className="sec-title"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                <img className="cloud" src={clouds26} alt="cloud" />
                <h2>
                  <span>Why is graphic design important?</span>
                </h2>
              </div>
            </div>
          </Row>
          <Row className="g-5 teammembers-wrap">
            {whyusdatalist.map((whyusdata) => (
              <div
                className="col-lg-6 col-md-6 col-sm-12 col-xs-12"
                key={whyusdata.id}
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                <div className="crumina-module crumina-teammembers-item icon_box_left">
                  <div className="teammembers-thumb">
                    <img src={whyusdata.Img} alt="team member" />
                  </div>

                  <div className="teammember-content">
                    <div className="teammembers-item-prof">
                      {whyusdata.Title}
                    </div>

                    <p>{whyusdata.Desc}</p>
                  </div>
                </div>
              </div>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
}
